import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { classes } from '@silkpwa/module/util/classes';

interface AddressFormButtonProps {
    label: string;
    disabled: boolean;
}

export const AddressFormButton: React.FC<AddressFormButtonProps> = ({
    label,
    disabled,
}) => {
    const t = usePhraseTranslater();
    return (
        <div className={classes(fStyles.formField, fStyles.actionButton)}>
            <button data-test="address-submit" type="submit" className={fStyles.checkoutButton} disabled={disabled}>{t(label)}</button>
        </div>
    );
};

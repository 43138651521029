import React, { useEffect, useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useMutation, useReactiveVar } from '@apollo/client';
import { getSessionStorageData, removeSessionStorageData, setSessionStorageData } from 'ui/util/session-storage';
import {
    cartIdVar,
    cartVar,
    isPaymentMethodSetVar,
    paymentMethodsVar,
    placeOrderErrorVar,
    IAvailablePaymentMethod,
    CC_VAULT,
    customerVar,
    paymentMethodVariablesVar,
    validatePaymentMethodVar,
} from 'ui/page/checkout-page/checkout-state';
import { CHECKOUT_PAYMENT_METHOD } from 'ui/page/checkout-page/events';
import { CheckoutEvent } from 'ui/page/checkout-page/checkout-event';
import { SET_PAYMENT_METHOD } from 'graphql/cart/payment-method';
import { LoadingImage } from 'ui/component/loading-image';
import { MethodHeader } from '../method-header/method-header';
import { PaymentMethod } from '../payment-method/payment-method';
import { AuthorizeNet } from '../authorizenet/authorizenet';
import { PaypalExpress } from '../paypal/paypal-express';
import { Repay } from '../repay/repay';
import { Braintree } from '../braintree/braintree';
import { CCVault } from '../cc-vault/cc-vault';
import { Bambora } from '../bambora/bambora';
import { TermsMethod } from '../terms-method/terms-method';
import { CreditKey } from '../credit-key/credit-key';
import { Option } from './option';
import styles from './style.css';

export interface IOnVariablesChange {
    (variables: {[key: string]: any}): void;
}

export interface IPaymentOptionsProps {
    setBeforePlaceOrder: (beforePlaceOrder: () => any) => void;
    setAfterPlaceOrder: (beforePlaceOrder: () => any) => void;
}

export interface IPaymentMethodParams {
    onVariablesChange: (variables: React.SetStateAction<{}>) => void;
    setBeforePlaceOrder: (beforePlaceOrder: () => any) => void;
    setAfterPlaceOrder: (afterPlaceOrder: () => any) => void;
    selectedMethod?: IAvailablePaymentMethod;
}

const paymentMethodComponents: {[key: string]: React.ElementType} = {
    purchaseorder: PaymentMethod,
    checkmo: TermsMethod,
    checkmo_international: TermsMethod,
    creditkey_gateway: CreditKey,
    free: PaymentMethod,
    authorizenet_directpost: AuthorizeNet,
    paypal_express: PaypalExpress,
    repay: Repay,
    braintree: Braintree,
    chefworksbambora: Bambora,
    cc_vault: CCVault,
};
export const isVault = (code: string): boolean => code.startsWith(`${CC_VAULT}_`);

export const getActualMethodCode = (code: string): string => (isVault(code) ? CC_VAULT : code);

export const PaymentOptions: React.FC<IPaymentOptionsProps> = ({ setBeforePlaceOrder, setAfterPlaceOrder }) => {
    const t = usePhraseTranslater();
    const cart = useReactiveVar(cartVar);
    const cartId = useReactiveVar(cartIdVar);
    const isPaymentMethodSet = useReactiveVar(isPaymentMethodSetVar);
    const availablePaymentMethods: IAvailablePaymentMethod[]|null = useReactiveVar(paymentMethodsVar);
    const [selectedMethod, setSelectedMethod] = useState({});
    const [selectedMethodCode, setSelectedMethodCode] = useState('');
    const [error, setError] = useState('');
    const [paymentMethodVariables, setPaymentMethodVariables] = useState({});
    const [showMoreMethod, setShowMoreMethod] = useState(false);
    const showAllMethod = (!isPaymentMethodSet && !getSessionStorageData('selectedPaymentMethod', true)) || showMoreMethod || error;
    const customer = useReactiveVar(customerVar);

    const setCurrentPaymentMethod = (method?: IAvailablePaymentMethod): void => {
        setSelectedMethod(method || {});
        setSelectedMethodCode(method?.code ?? '');
        if (method === undefined || method?.code === '') {
            removeSessionStorageData('selectedPaymentMethod');
            setPaymentMethodVariables({});
        }
    };

    const [savePaymentMethod, { loading }] = useMutation(SET_PAYMENT_METHOD, {
        variables: {
            cartId,
            paymentMethodCode: selectedMethodCode,
            ...paymentMethodVariables,
        },
        onError: (error) => {
            isPaymentMethodSetVar(false);
            setError(error.message);
            setCurrentPaymentMethod();
            throw new Error(error.message);
        },
        onCompleted: (data) => {
            paymentMethodVariablesVar(paymentMethodVariables);
            cartVar(data.setPaymentMethodOnCart.cart);
        },
    });

    /**
     * Declare the 'save payment before place order' action as a callback with a type 'function'. Its type is checked on
     * the before 'place-order' action.
     */
    const savePaymentBeforePlaceOrderAction = () => savePaymentMethod;

    const handlePaymentMethodVariablesChange = (variables: React.SetStateAction<{}>): void => {
        setPaymentMethodVariables(variables);
    };

    const getMethodComponentByCode = (
        code: string,
    ): React.ElementType => paymentMethodComponents[getActualMethodCode(code)];

    const getAvailableMethodByCode = (
        code: string,
    ): IAvailablePaymentMethod => {
        const currentSelectedMethod: IAvailablePaymentMethod = selectedMethod as IAvailablePaymentMethod;
        if (currentSelectedMethod && currentSelectedMethod.details) {
            return currentSelectedMethod;
        }
        if (availablePaymentMethods) {
            return availablePaymentMethods.find((method: IAvailablePaymentMethod) => method.code === code);
        }

        return {
            payment_method_code: '',
            public_hash: '',
            code: '',
            title: '',
        };
    };

    const onClickPaymentOption = (method: IAvailablePaymentMethod) => {
        setError('');
        placeOrderErrorVar('');
        setShowMoreMethod(false);
        const { code } = method;
        if (code in paymentMethodComponents || isVault(code)) {
            setCurrentPaymentMethod(method);
            if (code !== selectedMethodCode) {
                setSessionStorageData('selectedPaymentMethod', code);
                /**
                 * We have to reset `setBeforePlaceOrder` callback when switching between payment methods. It can be
                 * overridden inside a certain selected payment method component. Why - because not all the
                 * payment methods components require to redefine this callback.
                 */
                setBeforePlaceOrder(savePaymentBeforePlaceOrderAction);
                /**
                 * We also need to reset validatePaymentMethod callback when switching between payment methods.
                 */
                validatePaymentMethodVar(() => true);
                /**
                 * We have to reset `paymentMethodVariables` on click the payment method option in order
                 * not to send additional information now, it has to be sent out during `beforePlaceOrder` action.
                 */
                setPaymentMethodVariables({});
                /**
                 * We have to send the actual payment method code instead of observable 'selectedMethodCode' as it is
                 * not updated at this moment even though we have used setter 'setSelectedMethodCode'.
                 */
                if (code !== 'braintree' && getActualMethodCode(code) !== 'cc_vault') { // braintree and vault cannot be saved without the card token
                    savePaymentMethod({ variables: { paymentMethodCode: code } }).then(() => {
                        isPaymentMethodSetVar(true);
                        setShowMoreMethod(false);
                    });
                } else { // For Braintree or Vault we can use session to remember the selected payment method
                    isPaymentMethodSetVar(true);
                    setShowMoreMethod(false);
                }
            }
        } else {
            setError(`Payment method "${code}" is no longer available`);
            setCurrentPaymentMethod();
        }
    };

    useEffect(() => {
        const selectSinglePaymentMethod = () => {
            if (availablePaymentMethods?.length === 1) {
                onClickPaymentOption(availablePaymentMethods[0]);
            }
        };
        const selectSessionPaymentMethod = () => {
            const sessionPaymentMethod = getSessionStorageData('selectedPaymentMethod', true);
            if (typeof sessionPaymentMethod === 'string' && sessionPaymentMethod.trim() !== '') {
                const method = availablePaymentMethods.find(
                    (method: IAvailablePaymentMethod) => method.code === sessionPaymentMethod,
                );
                if (method) {
                    setSelectedMethod(sessionPaymentMethod);
                    onClickPaymentOption(getAvailableMethodByCode(sessionPaymentMethod));
                } else {
                    setCurrentPaymentMethod(cart?.selected_payment_method);
                }
            } else {
                setCurrentPaymentMethod(cart?.selected_payment_method);
            }
        };
        if (availablePaymentMethods) {
            selectSinglePaymentMethod();
            selectSessionPaymentMethod();
        }
    }, [availablePaymentMethods]);
    useEffect(() => {
        /**
         * Register the callback action on 'beforePlaceOrder' in order to set payment method along with its updated
         * variables right before place order action is executed.
         */
        setBeforePlaceOrder(savePaymentBeforePlaceOrderAction);
    }, []);
    return (
        <>
            <CheckoutEvent eventName={CHECKOUT_PAYMENT_METHOD} eventData={selectedMethodCode} />
            <MethodHeader methodName="Payment Options" open identifier="payment-options" bottomDivider={!!customer}>
                {loading ? (
                    <div className={styles.smallLoadingImage}>
                        <LoadingImage />
                    </div>
                ) : (
                    <div className={styles.paymentMethodContent} data-test="payment-options">
                        <div className={styles.paymentMethodBody}>
                            {/* Selected payment option form */}
                            {isPaymentMethodSet && !showAllMethod &&
                                (getMethodComponentByCode(selectedMethodCode)
                                    ? React.createElement(getMethodComponentByCode(selectedMethodCode), {
                                        onVariablesChange: handlePaymentMethodVariablesChange,
                                        setBeforePlaceOrder,
                                        setAfterPlaceOrder,
                                        selectedMethod: getAvailableMethodByCode(selectedMethodCode),
                                    })
                                    : null)
                            }
                        </div>
                        <div className={styles.paymentMethodOption}>
                            {/* List of available payment method option */}
                            {showAllMethod && availablePaymentMethods && availablePaymentMethods.map(
                                (method: IAvailablePaymentMethod) => (
                                    <Option
                                        key={method.code}
                                        method={method}
                                        selectedMethodCode={selectedMethodCode}
                                        onClickPaymentOption={onClickPaymentOption}
                                    />
                                ),
                            )}
                        </div>
                        { availablePaymentMethods?.length > 1 && (
                        <div className={styles.paymentMethodfooter}>
                            <button
                                type="button"
                                className={styles.showMethodToggle}
                                onClick={() => {
                                    setShowMoreMethod(!showMoreMethod);
                                }}
                            >
                                {t('%1 Payment Methods', showAllMethod ? 'Less' : 'More')}
                            </button>
                        </div>
                        )}
                        { !availablePaymentMethods && (
                            <div className={styles.noPaymentMethod}>
                                {t('No payment methods are currently available')}
                            </div>
                        )}
                        {error && <div className={styles.error}>{error}</div>}
                    </div>
                )}
            </MethodHeader>
        </>
    );
};

import React from 'react';
import { StepHeader, IStepProps } from './step-header';
import { BillingAddressForm } from './billing/billing-address-form';

const BillingStep = (step: IStepProps) => {
    const {
        stepNumber,
        open,
        complete,
        disabled,
    } = step;
    return (
        <StepHeader stepNumber={stepNumber} stepName="Billing" isComplete={complete} open={open} disabled={disabled}>
            <BillingAddressForm isComplete={complete} />
        </StepHeader>
    );
};
BillingStep.stepKey = 'billing';

export { BillingStep };

import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useMutation, useReactiveVar } from '@apollo/client';
import { APPLY_GIFT_CARD_TO_CART, REMOVE_GIFT_CARD_FROM_CART } from 'graphql/cart/payment-method/gift-cart';
import { cartIdVar, cartVar } from 'ui/page/checkout-page/checkout-state';
import { formatCurrency } from 'ui/component/checkout/util/get-currency';
import { Process } from 'ui/component/checkout/components/process';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IGiftCardProps {
    updateCart: () => Promise<void>;
}

export const GiftCard: React.FC<IGiftCardProps> = ({ updateCart }) => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    const cart = useReactiveVar(cartVar);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [giftCard, setGiftCard] = useState('');
    const [appliedGiftCards, setAppliedGiftCards] = useState(cart?.applied_gift_cards || []);
    const [applyGiftCard] = useMutation(APPLY_GIFT_CARD_TO_CART, {
        variables: {
            cartId,
            giftCardCode: giftCard,
        },
        onCompleted: async (data) => {
            setAppliedGiftCards(data.applyGiftCardToCart.cart.applied_gift_cards);
            await updateCart();
            setLoading(false);
        },
        onError: (error) => {
            setError(error.message);
            setLoading(false);
        },
    });
    const [removeGiftCard] = useMutation(REMOVE_GIFT_CARD_FROM_CART, {
        variables: {
            cartId,
            giftCardCode: giftCard,
        },
        onCompleted: async (data) => {
            setAppliedGiftCards(data.removeGiftCardFromCart.cart.applied_gift_cards);
            setGiftCard('');
            await updateCart();
            setLoading(false);
        },
        onError: (error) => {
            setError(error.message);
            setLoading(false);
        },
    });
    const handleGiftCard = () => {
        if (!giftCard) {
            return;
        }
        setLoading(true);
        applyGiftCard();
    };
    const handleRemove = () => {
        setLoading(true);
        removeGiftCard();
    };
    return (
        <>
            {cart && (
                <div className={styles.giftCardContent} data-test="gift-card">
                    {!appliedGiftCards.length && (
                        <div className={styles.giftCardWrapper}>
                            <input
                                className={styles.giftCardInput}
                                onChange={(event) => {
                                    setError('');
                                    setGiftCard(event.currentTarget.value);
                                }}
                                type="text"
                                name="giftCard"
                                placeholder="Gift Card Code"
                            />
                            <button
                                className={styles.giftCardButton}
                                type="button"
                                onClick={handleGiftCard}
                                disabled={loading}
                            >
                                <span>{t('Apply')}</span>
                                <Process loading={loading} />
                            </button>
                        </div>
                    )}
                    <div className={styles.appliedGiftCards}>
                        {appliedGiftCards.map(appliedGiftCard => (
                            <div key={appliedGiftCard.code} className={styles.appliedGiftCard}>
                                <div>
                                    <span className={classes(styles.label, styles.colon)}>Gift Card</span>
                                    <span className={styles.giftCardBalance}>
                                        {formatCurrency(appliedGiftCard.current_balance)}
                                    </span>
                                </div>
                                <button className={styles.removeGiftCard} type="button" onClick={handleRemove}>
                                    {t('remove')}
                                </button>
                            </div>
                        ))}
                    </div>
                    {error && (
                        <div className={styles.error}>{error}</div>
                    )}
                </div>
            )}
        </>
    );
};

import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

interface IPreviewHeaderProps {
    handleClose: () => void;
    face: string;
}

export const PreviewHeader: React.FC<IPreviewHeaderProps> = ({ handleClose, face }) => {
    const t = usePhraseTranslater();
    const faceMap = [
        { face: 'front', title: 'front' },
        { face: 'back', title: 'back' },
        { face: 'left', title: 'left' },
        { face: 'leftside', title: 'left' },
        { face: 'right', title: 'right' },
        { face: 'rightside', title: 'right' },
    ];

    const getFaceTitle = (face: string): string => {
        const match = faceMap.find(fm => fm.face === face);
        return match?.title || face;
    };

    return (
        <>
            <button
                type="button"
                className={styles.closeButton}
                onClick={handleClose}
            >
                <i className="fa-solid fa-xmark" />
            </button>
            <div className={styles.previewTitle}>
                {t(getFaceTitle(face))}
            </div>
        </>
    );
};

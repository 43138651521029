import React from 'react';
import { StepHeader, IStepProps } from './step-header';
import { Embroidery } from './embroidery/embroidery';

const EmbroideryStep = (step: IStepProps) => {
    const {
        stepNumber,
        open,
        complete,
        disabled,
    } = step;
    return (
        <>
            <StepHeader stepNumber={stepNumber} stepName="Embroidery" isComplete={complete} open={open} disabled={disabled}>
                <Embroidery />
            </StepHeader>
        </>
    );
};
EmbroideryStep.stepKey = 'embroidery';

export { EmbroideryStep };

import React, { useEffect, useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from 'ui/component/checkout/styles/style.css';

export interface IStepProps {
    stepNumber: number;
    open: boolean;
    complete: boolean;
    disabled: boolean;
}

export interface IStepHeaderProps {
    stepName: string;
    stepNumber: number;
    isComplete: boolean;
    open: boolean;
    disabled: boolean;
    children?: React.ReactNode;
}

export const StepHeader: React.FC<IStepHeaderProps> = ({
    stepNumber, stepName, isComplete, open, children, disabled,
}) => {
    const [isOpen, setIsOpen] = useState(open);

    function showHideStep() {
        if (!disabled) {
            setIsOpen(isOpen => !isOpen);
        }
    }
    const stepClass = isComplete ? 'complete' : 'incomplete';
    useEffect(() => {
        setIsOpen(open);
    }, [open]);
    return (
        <div className={`${styles.step} ${stepClass}`}>
            <div
                role="button"
                tabIndex={stepNumber - 1}
                className={classes({
                    [styles.stepHeader]: true,
                    [styles.active]: isOpen,
                })}
                onClick={showHideStep}
                onKeyDown={showHideStep}
            >
                {isComplete && (
                    <span className={styles.stepTick}><i className="fa-solid fa-check" /></span>
                )}
                {!isComplete && (
                    <span className={styles.stepNumber}>{stepNumber}</span>
                )}
                <span className={styles.stepName}>{stepName}</span>
                <span className={styles.stepToggle}>
                    <i className={isOpen ? 'fa-solid fa-minus' : 'fa-solid fa-plus'} />
                </span>
            </div>
            {isOpen && (
                <div
                    className={classes(styles.stepContent, styles[`stepContent${stepName}`])}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

import React from 'react';
import styles from './style.css';

interface IPreviewProps {
    handleNext: () => void;
    handlePrevious: () => void;
}

export const PreviewNav: React.FC<IPreviewProps> = ({ handleNext, handlePrevious }) => (
    <div className={styles.previewNavigation}>
        <button
            type="button"
            className={styles.previewButton}
            onClick={handlePrevious}
        >
            <i className="fa-solid fa-chevron-left" />
        </button>
        <button
            type="button"
            className={styles.nextButton}
            onClick={handleNext}
        >
            <i className="fa-solid fa-chevron-right" />
        </button>
    </div>
);

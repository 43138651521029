import { gql, TypedDocumentNode } from '@apollo/client';
import { CART_FIELDS } from './fragments';

export const SET_SHIPPING_METHOD: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation SET_SHIPPING_METHOD(
        $cartId: String!
        $carrierCode: String!
        $methodCode: String!
        $validateAddress: Boolean
    ) {
        setShippingMethodsOnCart(input: {
            cart_id: $cartId
            shipping_methods: [
                {
                    carrier_code: $carrierCode
                    method_code: $methodCode
                }
            ]
            validate_address: $validateAddress
        }) {
            cart {
                ...CartFields
            }
            valid_address {
                firstname
                lastname
                street
                city
                postcode
                region {
                    code
                    label
                }
                country {
                    code
                    label
                }
                telephone
            }
            original_address {
                firstname
                lastname
                street
                city
                postcode
                region {
                    code
                    label
                }
                country {
                    code
                    label
                }
                telephone
            }
        }
    }
`;

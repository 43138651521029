import React, { useEffect } from 'react';
import styles from './style.css';

export const Modal = ({ isOpen, content, onClose }) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, onClose]);
    if (!isOpen) {
        return null;
    }
    return (
        <div className={styles.modal} onClick={onClose} role="presentation">
            <div className={styles.modalContent}>
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={{ __html: content }} />
                <button type="button" className={styles.closeButton} onClick={onClose}>
                    <i className="fa-solid fa-xmark" />
                </button>
            </div>
        </div>
    );
};

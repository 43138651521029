import React from 'react';
import visa from 'assets/images/payment-services/visa.svg';
import mastercard from 'assets/images/payment-services/mastercard.svg';
import amex from 'assets/images/payment-services/amex.svg';
import styles from './style.css';

export const AcceptedCards = () => (
    <div className={styles.acceptedCards}>
        <p>We Accept</p>
        <div>
            <img src={visa} alt="Visa" />
            <img src={mastercard} alt="Mastercard" />
            <img src={amex} alt="Amex" />
        </div>
    </div>
);

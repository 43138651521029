import { gql, TypedDocumentNode } from '@apollo/client';

export const DELETE_CUSTOMER_ADDRESS: TypedDocumentNode = gql`
    mutation deleteCustomerAddress($id: Int!) {
        deleteCustomerAddress(id: $id)
    }
`;

export const CREATE_CUSTOMER_ADDRESS: TypedDocumentNode = gql`
    mutation createCustomerAddress(
        $firstname: String!
        $lastname: String!
        $company: String
        $street: [String]!
        $city: String!
        $region: String!
        $postcode: String!
        $countryCode: String!
        $telephone: String!
    ) {
        createCustomerAddress(
            input: {
                firstname: $firstname
                lastname: $lastname
                company: $company
                street: $street
                city: $city
                region: $region
                postcode: $postcode
                country_code: $countryCode
                telephone: $telephone
            }
        ) {
            id
            firstname
            lastname
            street
            city
            region {
                region_code
                region
            }
            postcode
            country_code
            telephone
        }
    }
`;

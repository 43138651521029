import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { customerVar } from 'ui/page/checkout-page/checkout-state';
import { Signup } from './signup';

export const Membership: React.FC<{}> = () => {
    const customer = useReactiveVar(customerVar);
    if (customer) return null;
    return <Signup />;
};

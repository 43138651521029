import React, { useEffect } from 'react';
import {
    IAvailablePaymentMethod,
} from 'ui/page/checkout-page/checkout-state';
import { IOnVariablesChange } from '../payment-options';
import { Terms } from '../payment-options/buttons-content/terms';
import { PaymentInfo } from '../payment-method/payment-info';
import optionsStyle from '../payment-options/style.css';

export const TermsMethod = (
    {
        onVariablesChange,
        selectedMethod,
    }: { onVariablesChange: IOnVariablesChange; selectedMethod: IAvailablePaymentMethod },
) => {
    useEffect(() => {
        onVariablesChange({
            paymentMethodCode: selectedMethod?.code ?? '',
        });
    }, [selectedMethod]);

    if (!selectedMethod || !selectedMethod.code) {
        return (<PaymentInfo />);
    }

    const { title, code } = selectedMethod;
    const selectedMethodInfo = title || code;

    return (
        <div className={optionsStyle.methodInfo}>
            <Terms title={selectedMethodInfo} />
        </div>
    );
};

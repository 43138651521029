/* eslint-disable camelcase */
import { gql, TypedDocumentNode } from '@apollo/client';

interface ICustomerPublicDetails {
    welcome_message: string;
}

export const GET_CUSTOMER_PUBLIC_DETAILS: TypedDocumentNode<ICustomerPublicDetails> = gql`
    query GET_CUSTOMER_PUBLIC_DETAILS(
        $email: String!
    ) {
        getCustomerPublicDetails(email: $email) {
            welcome_message
        }
    }
`;

import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_CUSTOMER_LOYALTY_BALANCE: TypedDocumentNode = gql`
    query GET_CUSTOMER_LOYALTY_BALANCE {
        customerLoyalty {
            amount
            formatAmount
            formatStrings {
                unit {
                    canButton
                }
            }
            maxAmount
            spend
            visibleOnCart
            visibleOnMiniCart
            }
        }
    `;

export const APPLY_LOYALTY_POINTS_TO_CART: TypedDocumentNode = gql`
    mutation APPLY_LOYALTY_POINTS_TO_CART(
        $cartId: String!
        $points: Int!
    ) {
        applyLoyaltyPointsToCart(
            cart_id: $cartId
            points: $points
        )
    }
`;

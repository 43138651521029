import { gql, TypedDocumentNode } from '@apollo/client';

interface ISetGuestEmailOnCartInput {
    input: {
        'cart_id': string;
        email: string;
    };
}

interface ISetGuestEmailOnCartOutput {
    setGuestEmailOnCart: {
        email: string;
    };
}

export const SET_GUEST_EMAIL_ON_CART: TypedDocumentNode<ISetGuestEmailOnCartOutput, ISetGuestEmailOnCartInput> = gql`
    mutation SET_GUEST_EMAIL_ON_CART($input: SetGuestEmailOnCartInput!) {
        setGuestEmailOnCart(input: $input) {
            cart {
                email
            }
        }
    }
`;

// TODO: Replace this with proper validator library to cover all the input fields
// This is temporary validator due to limited usage.

const hasDigit = (password: string) => /[0-9]/.test(password);

const hasLowercase = (password: string) => /[a-z]/.test(password);

const hasUppercase = (password: string) => /[A-Z]/.test(password);

const hasSpecialCharacter = (password: string) => /\W/.test(password);

export const isValidLength = (password: string, minLength: number) => password.length >= minLength;

export const validatePassword = (password: string, minChatSet = 3) => {
    let validCount = 0;

    if (hasDigit(password)) validCount += 1;
    if (hasLowercase(password)) validCount += 1;
    if (hasUppercase(password)) validCount += 1;
    if (hasSpecialCharacter(password)) validCount += 1;

    return validCount >= minChatSet;
};

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useReactiveVar } from '@apollo/client';
import { cartSummaryExtraDataVar, cartSummaryTotalsVar } from 'ui/page/checkout-page/checkout-state';
import { Divider } from 'ui/component/checkout/components/divider';
import { CartTotal } from 'ui/component/checkout/cart/cart-total';
import { CartSummary } from 'ui/component/checkout/cart/cart-summary';
import { MethodHeader } from '../methods/method-header/method-header';

export const Summary: React.FC<{}> = () => {
    const cartSummaryData = useReactiveVar(cartSummaryTotalsVar);
    const cartExtraData = useReactiveVar(cartSummaryExtraDataVar);
    const isMobile = useMediaQuery({ query: '(max-width: 1041px)' });
    const cartTotal = <CartTotal cartSummaryData={cartSummaryData} />;
    return isMobile && (
        <MethodHeader
            open
            methodName="Order Summary"
            collapsedContent={cartTotal}
            identifier="order-summary"
        >
            <CartSummary cartSummaryData={cartSummaryData} cartExtraData={cartExtraData} />
            <Divider />
            {cartTotal}
        </MethodHeader>
    );
};

import React, { useState, useRef } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useReactiveVar, useMutation } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import ReCAPTCHA from 'react-google-recaptcha';
import { configVar } from 'ui/page/checkout-page/checkout-state';
import { REQUEST_RESET_PASSWORD } from 'graphql/customer/customer';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import styles from './style.css';

export const ForgotPassword = () => {
    const t = usePhraseTranslater();
    const config = useReactiveVar(configVar);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [email, setEmail] = useState('');
    const [requestResetPassword, { loading, error }] = useMutation(REQUEST_RESET_PASSWORD, {
        onCompleted: () => {
            setShowSuccessMessage(true);
        },
    });
    const handleSubmit = async (event) => {
        event.preventDefault();
        let recaptchaToken: string | null = '';
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
            recaptchaToken = await recaptchaRef.current.executeAsync();
        }
        await requestResetPassword({
            variables: { email },
            context: {
                headers: {
                    'X-ReCaptcha': recaptchaToken,
                },
            },
        });
    };
    return (
        <>
            { showSuccessMessage && (
                <div className={styles.success}>
                    Your password reset request has been received. Please check your inbox
                </div>
            )}
            { !showSuccessMessage && (
                <form onSubmit={handleSubmit} className={styles.resetPasswordForm}>
                    {error && <div className="error">{error.message}</div>}
                    <div className={classes(fStyles.formHeader)}>
                        <div className={fStyles.formTitle}>{t('Forgot Password')}</div>
                    </div>
                    <div className={classes(fStyles.formField)}>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder={t('Email')}
                            onChange={event => setEmail(event.target.value)}
                        />
                    </div>
                    {config?.recaptcha_sitekey && (
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={config?.recaptcha_sitekey}
                            size="invisible"
                        />
                    )}
                    <div className={classes(fStyles.formField)}>
                        <button type="button" className={fStyles.checkoutButton} onClick={handleSubmit} disabled={loading}>
                            {t('Reset My Password')}
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};

import React, { useEffect, useState, useRef } from 'react';
import braintree, { BraintreeError, HostedFields } from 'braintree-web';
import { useMutation, useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { GET_BRAINTREE_TOKEN } from 'graphql/cart/payment-method/braintree';
import { SET_PAYMENT_METHOD_BRAINTREE } from 'graphql/cart/payment-method';
import { StandardLoadingImage } from 'ui/component/loading-image';
import {
    cartIdVar,
    cartVar,
    isPaymentMethodSetVar,
    isGuestVar,
    vaultMethodsEnabledVar,
    validatePaymentMethodVar,
} from 'ui/page/checkout-page/checkout-state';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { AcceptedCards } from '../credit-card-form/accepted-cards';
import { IPaymentMethodParams } from '../payment-options';
import cStyles from '../credit-card-form/style.css';
import styles from './style.css';

export const Braintree = ({ setBeforePlaceOrder, setAfterPlaceOrder }: IPaymentMethodParams) => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    const isGuest = useReactiveVar(isGuestVar);
    const vaultMethods = useReactiveVar(vaultMethodsEnabledVar);
    const isVaultMethodEnabled = vaultMethods.some(method => method.code === 'braintree_cc_vault');
    const [isActivePaymentTokenEnabler, setIsActivePaymentTokenEnabler] = useState(false);
    const isActivePaymentTokenEnablerRef = useRef(isActivePaymentTokenEnabler);
    const [getBraintreeToken] = useMutation(GET_BRAINTREE_TOKEN);
    const [savePaymentMethod, { loading }] = useMutation(SET_PAYMENT_METHOD_BRAINTREE, {
        onError: (error) => {
            isPaymentMethodSetVar(false);
            throw new Error(error.message);
        },
        onCompleted: (data) => {
            cartVar(data.setPaymentMethodOnCart.cart);
        },
    });

    useEffect(() => {
        isActivePaymentTokenEnablerRef.current = isActivePaymentTokenEnabler;
    }, [isActivePaymentTokenEnabler]);

    useEffect(() => {
        const setupBraintree = async (braintreeToken: string) => {
            const client = await braintree.client.create({
                authorization: braintreeToken,
            });
            const hostedFields: HostedFields = await braintree.hostedFields.create({
                client,
                fields: {
                    cardholderName: {
                        selector: '#cardholderNameContainer',
                    },
                    number: {
                        selector: '#cardNumberContainer',
                    },
                    cvv: {
                        selector: '#cvvContainer',
                    },
                    expirationDate: {
                        selector: '#expiryDateContainer',
                    },
                },
                styles: {
                    ':focus': {
                        color: 'blue',
                    },
                    '.valid': {
                        color: 'green',
                    },
                    '.invalid': {
                        color: 'red',
                    },
                },
            });
            setBeforePlaceOrder(() => async () => new Promise<void>(async (resolve, reject) => {
                try {
                    const state = hostedFields.getState();
                    let fields = Object.keys(state.fields);
                    if (state.fields.cardholderName.isEmpty) {
                        fields = fields.filter(field => field !== 'cardholderName');
                    }
                    // @ts-ignore - types for braintree are not up to date
                    hostedFields.tokenize({ fieldsToTokenize: fields }, async (err, payload) => {
                        if (err) {
                            reject(err);
                            return;
                        }
                        try {
                            await savePaymentMethod({
                                variables: {
                                    cartId,
                                    paymentMethodCode: 'braintree',
                                    paymentMethodNonce: payload.nonce,
                                    isActivePaymentTokenEnabler: isActivePaymentTokenEnablerRef.current,
                                },
                            });
                            resolve();
                        } catch (error) {
                            reject(error);
                        }
                    });
                } catch (error) {
                    getBraintreeToken({
                        variables: {
                            cartId,
                        },
                        onCompleted: (data) => {
                            setupBraintree(data.createBraintreeClientToken);
                        },
                    });
                    switch ((error as BraintreeError).type) {
                        case 'CUSTOMER':
                            reject(new Error('Please confirm all credit card fields are correct'));
                            break;
                        default:
                            reject(new Error('An error occurred, please try again'));
                            break;
                    }
                }
            }));
            setAfterPlaceOrder(() => async () => {
                hostedFields.teardown();
                getBraintreeToken({
                    variables: {
                        cartId,
                    },
                    onCompleted: (data) => {
                        setupBraintree(data.createBraintreeClientToken);
                    },
                });
            });
            validatePaymentMethodVar(() => {
                const state = hostedFields.getState();
                let isValid = true;
                Object.keys(state.fields).forEach((key) => {
                    /* eslint-disable @typescript-eslint/no-unsafe-member-access - this is as per Braintree Documentation examples */
                    if (!state.fields[key].isValid && key !== 'cardholderName') {
                        state.fields[key].container.classList.add(styles.braintreeHostedFieldsInvalid);
                        isValid = false;
                    }
                    /* eslint-enable */
                });
                return isValid;
            });
        };
        getBraintreeToken({
            variables: {
                cartId,
            },
            onCompleted: (data) => {
                setupBraintree(data.createBraintreeClientToken);
            },
        });
    }, []);

    const formTitle = t('Credit Card');
    return (
        <>
            { !loading ? (
                <form id="braintree-form" className="braintree">
                    {formTitle && (
                        <div className={classes(fStyles.formHeader)}>
                            <div className={fStyles.formNormalTitle}>{t(formTitle)}</div>
                        </div>
                    )}
                    <div className={fStyles.formField}>
                        <label htmlFor="cardholderName">Cardholder Name</label>
                        <div id="cardholderNameContainer" className={styles.braintreeInputClass} />
                    </div>
                    <div className={fStyles.formField}>
                        <label htmlFor="cardNumber" className={cStyles.required}>Card Number</label>
                        <div id="cardNumberContainer" className={styles.braintreeInputClass} />
                    </div>
                    <div className={fStyles.formField}>
                        <label htmlFor="expiryDate" className={cStyles.required}>Expiry Date</label>
                        <div id="expiryDateContainer" className={styles.braintreeInputClass} />
                    </div>
                    <div className={fStyles.formField}>
                        <label htmlFor="cvv" className={cStyles.required}>CVV</label>
                        <div id="cvvContainer" className={styles.braintreeInputClass} />
                    </div>
                    { !isGuest && isVaultMethodEnabled && (
                        <div className={cStyles.extraRowWrapper}>
                            <div className={cStyles.checkboxContainer}>
                                <label htmlFor="savePaymentDetails" className={cStyles.checkboxLabel}>
                                    <input
                                        type="checkbox"
                                        id="savePaymentDetails"
                                        checked={isActivePaymentTokenEnabler}
                                        onChange={(e) => {
                                            setIsActivePaymentTokenEnabler(e.target.checked);
                                        }}
                                        className={cStyles.checkbox}
                                    />
                                    <span className={cStyles.checkboxText}>
                                        {t('Save payment information for future purchases')}
                                    </span>
                                </label>
                            </div>
                        </div>
                    ) }
                    <AcceptedCards />
                </form>
            ) : (
                <StandardLoadingImage />
            )}
        </>
    );
};

import { gql, TypedDocumentNode } from '@apollo/client';
import { CART_FIELDS } from './fragments';

export const SET_BILLING_ADDRESS: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation SET_BILLING_ADDRESS(
        $cartId: String!
        $firstname: String!
        $lastname: String!
        $company: String
        $street: [String]!
        $city: String!
        $region: String!
        $postcode: String!
        $countryCode: String!
        $telephone: String!
        $saveInAddressBook: Boolean
        $sameAsShipping: Boolean
    ) {
    setBillingAddressOnCart(
        input: {
            cart_id: $cartId
            billing_address: {
                address: {
                    firstname: $firstname
                    lastname: $lastname
                    company: $company
                    street: $street
                    city: $city
                    region: $region
                    postcode: $postcode
                    country_code: $countryCode
                    telephone: $telephone
                    save_in_address_book: $saveInAddressBook
                }
                same_as_shipping: $sameAsShipping
            }
        }
    ) {
        cart {
            ...CartFields
        }
      }
    }
`;

import React from 'react';
import { CreditCardForm, ICreditCardInfo } from '../credit-card-form/credit-card-form';
import { IOnVariablesChange } from '../payment-options';

export const AuthorizeNet = ({ onVariablesChange }: { onVariablesChange: IOnVariablesChange }) => {
    const handleCreditCardInfoChange = (creditCardInfo: ICreditCardInfo) => {
        onVariablesChange({
            paymentMethodCode: 'authorizenet_directpost',
            ...creditCardInfo,
        });
    };
    return (
        <>
            <CreditCardForm
                onCreditCardInfoChange={handleCreditCardInfoChange}
                formId="authorizennet-form"
                formTitle="Credit Card"
            />
        </>
    );
};

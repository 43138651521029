import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { IAgreement, IStoreConfig } from 'graphql/config/config';
import {
    configVar,
    IAcceptedTerm,
    IAgreedTerm,
    termsAgreedVar,
    termsForPlaceOrderVar,
} from 'ui/page/checkout-page/checkout-state';
import { Modal } from 'ui/component/checkout/components/modal/modal';
import {
    getAcceptedTermsForPlaceOrder,
    setTermsToSessionStorage,
} from './process-checkout-terms';
import styles from './style.css';

export const TermsInputs = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const t = usePhraseTranslater();
    const config: IStoreConfig|null = useReactiveVar<IStoreConfig|null>(configVar);
    const termsStatus: boolean = config?.terms_and_conditions_status === '1';
    const configTerms: IAgreement[] = config?.terms_and_conditions_agreements ?? [];
    const termsAgreed: IAgreedTerm = useReactiveVar(termsAgreedVar);
    const defaultAgreementValue = !termsStatus;

    useEffect((): void => {
        if (config) {
            const termsSet: boolean = Object.keys(termsAgreed).length > 0;
            const initialTermsAgreed: IAgreedTerm = configTerms.reduce(
                (acc: {[key: string]: boolean}, agreement: IAgreement) => {
                    acc[agreement.id] = termsStatus && termsSet
                        ? termsAgreed[agreement.id]
                        : defaultAgreementValue;
                    return acc;
                },
                {},
            );
            termsAgreedVar({
                ...initialTermsAgreed,
            });
            const initialTermsForPlaceOrder: IAcceptedTerm[] = getAcceptedTermsForPlaceOrder(
                termsStatus,
                configTerms,
                initialTermsAgreed,
            );
            termsForPlaceOrderVar(initialTermsForPlaceOrder as []);
            setTermsToSessionStorage(initialTermsAgreed, initialTermsForPlaceOrder);
        }
    }, []);
    const handleButtonClick = (content: string): void => {
        setModalContent(content);
        setIsModalOpen(!isModalOpen);
    };
    const handleCloseModal = (): void => {
        setIsModalOpen(false);
    };
    const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value, checked } = event.target;
        const newTermsAgreed: IAgreedTerm = {
            ...termsAgreed,
            [value]: checked,
        };
        termsAgreedVar(newTermsAgreed);
        const acceptedTermsForPlaceOrder: IAcceptedTerm[] = getAcceptedTermsForPlaceOrder(
            termsStatus,
            configTerms,
            newTermsAgreed,
        );
        termsForPlaceOrderVar(acceptedTermsForPlaceOrder as []);
        setTermsToSessionStorage(newTermsAgreed, acceptedTermsForPlaceOrder);
    };
    const termsInputs = config
        ? configTerms.map(
            (agreement: IAgreement) => (
                <div key={agreement.id} data-test={`terms-agreement-${agreement.id}`}>
                    <label className={styles.terms_label} htmlFor={agreement.id}>
                        <input
                            id={agreement.id}
                            type="checkbox"
                            className={styles.termsInput}
                            checked={termsAgreed[agreement.id] ?? defaultAgreementValue}
                            value={agreement.id}
                            onChange={handleTermsChange}
                            name={`terms-agreement-${agreement.id}`}
                        />
                        <button
                            className={styles.termsLink}
                            type="button"
                            onClick={() => handleButtonClick(agreement.content)}
                        >
                            {agreement.checkbox_text}
                        </button>
                    </label>
                </div>
            ),
        ) : null;

    return (
        <div
            className={classes({
                [styles.termsCheckboxesWrapper]: true,
                [styles.hidden]: !termsStatus,
            })}
        >
            <span>{t('You must agree to our:')}</span>
            {termsInputs}
            <Modal isOpen={isModalOpen} content={modalContent} onClose={handleCloseModal} />
        </div>
    );
};

import React from 'react';
import DOMPurify from 'dompurify';
import { classes } from '@silkpwa/module/util/classes';
import { formatCurrency } from '../../../../../graphql/tools';
import styles from './style.css';

export interface IShippingMethodMethod {
    // eslint-disable-next-line camelcase
    method_code: string;
    // eslint-disable-next-line camelcase
    method_title: string;
    // eslint-disable-next-line camelcase
    carrier_code: string;
    amount: {
        currency: string;
        value: number;
    };
    desc: string|null;
}

interface IShippingMethodProps {
    method: IShippingMethodMethod | null;
    handleClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    checked?: boolean;
}

export const ShippingMethod: React.FC<IShippingMethodProps> = ({
    method,
    handleClick,
    className = '',
    checked = false,
}) => {
    if (!method?.method_code) return null;
    return (
        <div data-test="shipping-method" className={styles.shippingMethodWrapperExternal}>
            <div className={classes(styles.shippingMethodWrapper, className)}>
                <div className={styles.action}>
                    <input
                        id={method.method_code}
                        className={styles.select}
                        type="radio"
                        name="shippingMethod"
                        value={method.method_code}
                        onChange={handleClick}
                        checked={checked}
                    />
                </div>
                <div className={styles.title}>
                    <label className={styles.label} htmlFor={method.method_code}>{method.method_title}</label>
                    { /* Estimated delivery date is a phase two feature and should not be displayed */ }
                    { /* <span>Estimated Delivery XXXX</span> */ }
                </div>
                <div className={styles.amount}>
                    <span>{formatCurrency(method.amount?.value, method.amount?.currency)}</span>
                </div>
            </div>
            {checked && method?.desc && (
                <div className={styles.descriptionWrapper}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(method.desc) }} />
                </div>
            )}
        </div>
    );
};

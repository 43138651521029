import React from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { REVOKE_CUSTOMER_TOKEN } from 'graphql/customer/mutations/revokeCustomerToken';
import {
    customerEmailVar,
    customerVar,
    isLoggedInVar,
    resetCustomer,
} from 'ui/page/checkout-page/checkout-state';
import { getCustomerEmail } from 'ui/component/checkout/util/get-from-customer';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import styles from './style.css';

interface ICustomerInfoProps {
    accountActions: any;
}

const CustomerInfo: React.FC<ICustomerInfoProps> = ({
    accountActions,
}) => {
    const t = usePhraseTranslater();
    const email = useReactiveVar(customerEmailVar);
    const customer = useReactiveVar(customerVar);
    const isLoggedIn = useReactiveVar(isLoggedInVar);
    const [revokeCustomerToken] = useMutation(REVOKE_CUSTOMER_TOKEN, {
        onError: () => {
            // prevents console log
        },
    });
    const handleEdit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (isLoggedIn) {
            // We don't allow to logout at checkout at this point
            // TODO: Prevent logOut method to redirect to homepage
            await accountActions.logOut();
            // revoke Customer token
            await revokeCustomerToken();
        }
        resetCustomer();
    };
    return (
        <>
            {email && (
                <>
                    <div className={styles.customerInfo}>
                        <span className={styles.email}>{getCustomerEmail(email)}</span>
                        {!customer && (
                            <button className={fStyles.buttonLink} id="edit" type="submit" onClick={handleEdit}>
                                {t('Edit')}
                            </button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
const ConnectedCustomerInfo = connectAccount(CustomerInfo);

export { ConnectedCustomerInfo as CustomerInfo };

import React, { useEffect } from 'react';
import ck from 'creditkey-js';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    configVar,
    placeOrderInfoVar,
    placeOrderLoadingVar,
    shouldScrollToLoaderVar,
    orderPlacedVar,
    CREDIT_KEY,
    IAvailablePaymentMethod,
} from 'ui/page/checkout-page/checkout-state';
import { IStoreConfig, ICreditKeyConfig } from 'graphql/config/config';
import { getStyles } from './get-styles';
import { PaymentInfo } from '../payment-method/payment-info';
import { CreditKey as CreditKeyButton } from '../payment-options/buttons-content/credit-key';
import { IPaymentMethodParams } from '../payment-options';
import { setTermsToSessionStorage } from '../../../place-order/terms/process-checkout-terms';
import optionsStyle from '../payment-options/style.css';

interface ICreditKeyEventData {
    action: string;
    type: string;
}

export const CreditKey = (
    {
        onVariablesChange,
        setBeforePlaceOrder,
        selectedMethod,
    }: IPaymentMethodParams,
) => {
    const t = usePhraseTranslater();
    const config: IStoreConfig|null = useReactiveVar<IStoreConfig|null>(configVar);

    const creditKeyConfig: ICreditKeyConfig = config?.creditkey_config ?? {
        endpoint: '',
        publicKey: '',
        checkoutMode: '',
        redirectUrl: '',
        isCreditKeyDisplayed: false,
    };
    const {
        checkoutMode,
        redirectUrl,
        isCreditKeyDisplayed,
    } = creditKeyConfig;

    const registerPostMessageCallback = (event: { data: string|null }): void => {
        if (!event || !event.data) {
            return;
        }

        let creditKeyEventData: ICreditKeyEventData|false;

        try {
            creditKeyEventData = JSON.parse(event.data) as ICreditKeyEventData;
        } catch (e) {
            creditKeyEventData = false;
        }

        if (!creditKeyEventData || !creditKeyEventData.action ||
            !creditKeyEventData.type || creditKeyEventData.type !== 'modal'
        ) {
            return;
        }

        const { action } = creditKeyEventData;
        if (action === 'cancel') {
            placeOrderInfoVar(t('CreditKey order processing has not been completed.'));
            shouldScrollToLoaderVar(true);
            setTimeout(() => {
                placeOrderInfoVar('');
                if (placeOrderLoadingVar()) {
                    placeOrderLoadingVar(false);
                }
            }, 3000);
        }

        if (action === 'complete') {
            orderPlacedVar(true);
            setTermsToSessionStorage({}, []);
            placeOrderInfoVar(t('CreditKey order processing has been completed.'));
            setTimeout(() => {
                placeOrderInfoVar('Please, wait, we are redirecting you to the success page...');
            }, 500);
        }
    };

    const creditKeyStylesId = `${CREDIT_KEY}_modal_styles`;
    const addModalStyles = (): void => {
        const styleElement = document.getElementById(creditKeyStylesId);
        if (!styleElement) {
            const styleScript = document.createElement('style');
            styleScript.id = creditKeyStylesId;
            styleScript.innerText = getStyles();
            document.body.appendChild(styleScript);
        }
    };
    const removeModalStyles = (): void => {
        const styleElement = document.getElementById(creditKeyStylesId);
        if (styleElement) {
            document.body.removeChild(styleElement);
        }
    };
    const removeMessageListener = (): void => {
        window.removeEventListener('message', registerPostMessageCallback, false);
    };
    const addMessageListener = (): void => {
        removeMessageListener();
        window.addEventListener('message', registerPostMessageCallback, false);
    };

    const processCreditKey = () => async () => {
        if (isCreditKeyDisplayed && redirectUrl && checkoutMode === 'modal') {
            addModalStyles();
            addMessageListener();
            ck.checkout(redirectUrl, 'modal');
            throw new Error(t('Info:Start CreditKey processing...'));
        } else {
            removeModalStyles();
            removeMessageListener();
            shouldScrollToLoaderVar(true);
            throw new Error(t('CreditKey configuration error.'));
        }
    };

    useEffect(() => {
        onVariablesChange({
            paymentMethodCode: selectedMethod?.code ?? '',
        });
    }, [selectedMethod]);

    useEffect(() => {
        setBeforePlaceOrder(processCreditKey);
    }, []);

    if (!creditKeyConfig || !isCreditKeyDisplayed) {
        return (<PaymentInfo infoText={t('Payment Method CreditKey is not available.')} />);
    }

    const isCreditKeySet = () => Boolean(selectedMethod && selectedMethod.code === CREDIT_KEY);
    if (!isCreditKeySet()) {
        return (<PaymentInfo />);
    }

    const {
        title,
        code,
    } = selectedMethod as IAvailablePaymentMethod;
    const selectedMethodInfo = title || code;

    return (
        <>
            {selectedMethod?.code && (
                <div className={optionsStyle.methodInfo}>
                    <CreditKeyButton title={selectedMethodInfo} />
                </div>
            )}
        </>
    );
};

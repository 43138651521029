import React, { useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { withMask } from 'use-mask-input';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { classes } from '@silkpwa/module/util/classes';
import { configVar } from 'ui/page/checkout-page/checkout-state';
import AutoComplete from './auto-complete';

interface IAddressFormFieldTextProps {
    label: string;
    name: string;
    error: string | undefined;
    onChange: any;
    value: string;
    autocomplete?: boolean;
    setAddress?: any;
    disabled?: boolean;
}

export const useGetInputRef = (name: string): React.RefObject<HTMLInputElement> => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (name === 'telephone' && inputRef.current) {
            withMask('+9{1,3}9{4,14}[-][9]{4}')(inputRef.current);
        }
    }, [name, inputRef]);

    return inputRef;
};

export const AddressFormFieldText: React.FC<IAddressFormFieldTextProps> = ({
    label,
    name,
    error,
    onChange,
    value,
    autocomplete,
    setAddress,
    disabled,
}) => {
    const t = usePhraseTranslater();
    const config = useReactiveVar(configVar);
    const hasErrors = error ?? '';
    const inputRef = useGetInputRef(name);
    return (
        <div
            className={classes({
                [fStyles.formFieldError]: hasErrors,
                [fStyles.formField]: true,
            })}
        >
            <label htmlFor={name}>{t(label)}</label>
            <input
                type="text"
                name={name}
                id={name}
                defaultValue={value}
                onChange={(e) => {
                    e.persist();
                    onChange(e);
                }}
                ref={inputRef}
                disabled={disabled}
            />
            {autocomplete &&
                config?.google_address_completion &&
                <AutoComplete ref={inputRef} setAddress={setAddress} />
            }
            {hasErrors && (<span className={fStyles.error}>{error}</span>)}
        </div>
    );
};

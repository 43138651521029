import React from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { DELETE_CUSTOMER_ADDRESS } from 'graphql/customer/address';
import { ICustomerAddress, customerVar } from 'ui/page/checkout-page/checkout-state';
import styles from './style.css';

interface IDeleteProp {
    address: ICustomerAddress;
    handleClose: () => void;
}

export const Delete: React.FC<IDeleteProp> = ({ address, handleClose }) => {
    const customer = useReactiveVar(customerVar);
    const [deleteAddress] = useMutation(DELETE_CUSTOMER_ADDRESS);
    const handleRemove = async () => {
        await deleteAddress({
            variables: {
                id: address.id,
            },
            onCompleted: () => {
                customerVar({
                    ...customer,
                    addresses: customer.addresses.filter(item => item.id !== address.id),
                });
                handleClose();
            },
        });
    };
    return (
        <div className={styles.deleteWindow}>
            <div className={styles.title}>
                <i className="fa-solid fa-circle-exclamation" />
                <span>Delete Address</span>
            </div>
            <div className={styles.message}>
                <span>Are you sure you want to delete this address?</span>
            </div>
            <div className={styles.address}>
                {address.company && (
                    <div className={styles.title}>{address.company}</div>
                )}
                <div>{`${address.firstname} ${address.lastname}`}</div>
                <div>{address.street}</div>
                <div>{`${address.city} ${address.region.region} ${address.postcode}`}</div>
                <div>{address.country_code}</div>
            </div>
            <div className={styles.actions}>
                <button type="button" className={styles.deleteNo} onClick={handleClose}>
                    Cancel
                </button>
                <button type="button" className={styles.deleteYes} onClick={handleRemove}>
                    Yes, Delete
                </button>
            </div>
        </div>
    );
};

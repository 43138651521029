import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import fStyles from 'ui/component/checkout/styles/form-style.css';

interface IAddressFormFieldCheckboxProps {
    label: string;
    name: string;
    checked: boolean;
    onChange?: any;
    error: string | undefined;
}

export const AddressFormFieldCheckbox: React.FC<IAddressFormFieldCheckboxProps> = ({
    label, name, checked, onChange, error,
}) => {
    const t = usePhraseTranslater();
    return (
        <div className={classes(fStyles.formField, fStyles.checkbox)}>
            <label htmlFor={name}>
                <input type="checkbox" id={name} name={name} checked={checked} value={name} onChange={onChange} />
                {t(label)}
            </label>
            {error && (<span className={fStyles.error}>{error}</span>)}
        </div>
    );
};

import { gql, TypedDocumentNode } from '@apollo/client';
import { CART_FIELDS } from '../fragments';

interface IMergeCartsInput {
    'source_cart_id': string;
    'destination_cart_id'?: string;
}

interface IMergeCartsOutput {
    mergeCarts: {
        [key: string]: any;
    };
}

export const MERGE_CARTS: TypedDocumentNode<IMergeCartsOutput, IMergeCartsInput> = gql`
    ${CART_FIELDS}
    mutation MERGE_CARTS($source_cart_id: String!, $destination_cart_id: String!) {
        mergeCarts(source_cart_id: $source_cart_id, destination_cart_id: $destination_cart_id) {
            ...CartFields
        }
    }
`;

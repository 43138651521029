import creditCardType, {
    types as CardType,
} from 'credit-card-type';

interface ISupportedCard {
    isSupported: boolean;
    cardTypeLabel: string;
    cardTypeCode: string;
}

export const AMERICAN_EXPRESS = 'AE';
export const MASTERCARD = 'MC';
export const VISA = 'VI';

const acceptedCodes: { [key: string]: string } = {
    'american-express': AMERICAN_EXPRESS,
    mastercard: MASTERCARD,
    visa: VISA,
};

const acceptedTypes: string[] = [
    AMERICAN_EXPRESS,
    MASTERCARD,
    VISA,
];

export const isAcceptedCard = (typeCode: string): boolean => acceptedTypes.includes(typeCode);

const isAllowed = (type: string): boolean => type === CardType.MASTERCARD || type === CardType.VISA ||
    type === CardType.AMERICAN_EXPRESS;

export const GetCreditCartType = (number: string): ISupportedCard => {
    const allTypes = creditCardType(number);
    const supportedTypes = allTypes.filter(card => isAllowed(card.type));

    const supportedCardType = supportedTypes[0] ?? { type: '' };
    const isSupported = supportedCardType.type !== '';

    const firstType = allTypes[0] ?? { type: '', niceType: '' };
    const {
        type,
        niceType,
    } = firstType;

    const cardTypeCode = acceptedCodes[type] ?? '';

    return {
        isSupported,
        cardTypeLabel: niceType,
        cardTypeCode,
    };
};

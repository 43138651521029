import { gql, TypedDocumentNode } from '@apollo/client';

export const APPLY_COUPON_TO_CART: TypedDocumentNode = gql`
    mutation APPLY_COUPON_TO_CART(
        $cartId: String!
        $couponCode: String!
    ) {
        applyCouponToCart(
            input: {
                cart_id: $cartId,
                coupon_code: $couponCode
            }
        ) {
            cart {
                applied_coupons {
                    code
                }
            }
        }
    }
`;

export const REMOVE_COUPON_FROM_CART: TypedDocumentNode = gql`
    mutation REMOVE_COUPON_FROM_CART(
        $cartId: String!
    ) {
        removeCouponFromCart(
            input:
            { cart_id: $cartId }
        ) {
            cart {
                applied_coupons {
                    code
                }
            }
        }
    }
`;

import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useMutation, useReactiveVar } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import { APPLY_COUPON_TO_CART, REMOVE_COUPON_FROM_CART } from 'graphql/cart/payment-method/coupon';
import { cartIdVar, cartVar } from 'ui/page/checkout-page/checkout-state';
import { Process } from 'ui/component/checkout/components/process';
import styles from './style.css';

interface ICouponProps {
    updateCart: () => Promise<void>;
}

export const Coupon: React.FC<ICouponProps> = ({ updateCart }) => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    const cart = useReactiveVar(cartVar);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [appliedCoupons, setAppliedCoupons] = useState(cart?.applied_coupons);
    const [applyCoupon] = useMutation(APPLY_COUPON_TO_CART, {
        variables: {
            cartId,
            couponCode,
        },
        onCompleted: async (data) => {
            setAppliedCoupons(data.applyCouponToCart.cart.applied_coupons);
            await updateCart();
            setLoading(false);
        },
        onError: (error) => {
            setError(error.message);
            setLoading(false);
        },
    });
    const [removeCoupon] = useMutation(REMOVE_COUPON_FROM_CART, {
        variables: {
            cartId,
        },
        onCompleted: async (data) => {
            setAppliedCoupons(data.removeCouponFromCart.cart.applied_coupons);
            setCouponCode('');
            await updateCart();
            setLoading(false);
        },
        onError: (error) => {
            setError(error.message);
            setLoading(false);
        },
    });
    const handleCoupon = () => {
        if (!couponCode) {
            return;
        }
        setLoading(true);
        applyCoupon();
    };
    const handleRemove = () => {
        setLoading(true);
        removeCoupon();
    };
    return (
        <>
            {cart && (
                <div className={styles.couponContent} data-test="coupon-code">
                    {!appliedCoupons && (
                        <div className={styles.couponWrapper}>
                            <input
                                className={styles.couponInput}
                                onChange={(event) => {
                                    setError('');
                                    setCouponCode(event.currentTarget.value);
                                }}
                                type="text"
                                name="coupon"
                                placeholder="Coupon Code"
                            />
                            <button
                                className={styles.couponButton}
                                type="button"
                                onClick={handleCoupon}
                                disabled={loading}
                            >
                                <span>{t('Apply')}</span>
                                <Process loading={loading} />
                            </button>
                        </div>
                    )}
                    {appliedCoupons && (
                        <div className={styles.appliedCoupons}>
                            {appliedCoupons.map(appliedCoupon => (
                                <div key={appliedCoupon.code} className={styles.appliedCoupon}>
                                    <div>
                                        <span className={classes(styles.label, styles.colon)}>Coupon Code</span>
                                        <span>{appliedCoupon.code}</span>
                                    </div>
                                    <button className={styles.removeCoupon} type="button" onClick={handleRemove}>
                                        {t('remove')}
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                    {error && (
                        <div className={styles.error}>{error}</div>
                    )}
                </div>
            )}
        </>
    );
};

import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_BRAINTREE_TOKEN: TypedDocumentNode = gql`
    mutation GET_BRAINTREE_TOKEN {
        createBraintreeClientToken
    }
`;

export const GET_BRAINTREE_PAYMENT_TOKENS: TypedDocumentNode = gql`
    query {
        customerPaymentTokens {
            items {
                details
                public_hash
                payment_method_code
                type
            }
        }
    }
`;

import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import amex from 'assets/images/payment-services/amex.svg';
import mastercard from 'assets/images/payment-services/mastercard.svg';
import visa from 'assets/images/payment-services/visa.svg';
import { IAvailablePaymentMethod } from 'ui/page/checkout-page/checkout-state';
import {
    isAcceptedCard,
    AMERICAN_EXPRESS,
    MASTERCARD,
    VISA,
} from '../../credit-card-form/get-credit-card-type';
import { CreditCard } from './credit-card';

const GetCardImageByType: React.FC<{ ccType: string }> = ({ ccType }) => {
    if (!isAcceptedCard(ccType)) {
        return (
            <i className="fa-regular fa-credit-card" />
        );
    }

    if (ccType === AMERICAN_EXPRESS) {
        return (
            <img src={amex} alt="Amex" />
        );
    }

    if (ccType === MASTERCARD) {
        return (
            <img src={mastercard} alt="Mastercard" />
        );
    }

    if (ccType === VISA) {
        return (
            <img src={visa} alt="Visa" />
        );
    }

    return null;
};

export const CreditCardVault: React.FC<IAvailablePaymentMethod> = ({ title, details }) => {
    const t = usePhraseTranslater();
    if (!details?.expirationDate) {
        return (
            <CreditCard title={title} />
        );
    }


    const {
        maskedCC,
        type,
        expirationDate,
    } = details;

    return (
        <>
            <GetCardImageByType ccType={type} />
            <span>{t('ENDING %1 ( EXPIRES: %2 )', maskedCC, expirationDate)}</span>
        </>
    );
};

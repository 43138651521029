import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    configVar,
    customerPasswordVar,
    customerVar,
    GetIsExplicitGuest,
    isGuestVar,
    SESSION_KEY,
    stepsCompletedVar,
} from 'ui/page/checkout-page/checkout-state';
import { IStoreConfig } from 'graphql/config/config';
import { Divider } from 'ui/component/checkout/components/divider';
import { classes } from '@silkpwa/module/util/classes';
import { isValidLength, validatePassword } from 'ui/component/checkout/util/validator';
import { removeSessionStorageData, setSessionStorageData } from 'ui/util/session-storage';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import styles from './style.css';

export const Signup: React.FC<{}> = () => {
    const t = usePhraseTranslater();
    const [error, setError] = useState('');
    const config = useReactiveVar<IStoreConfig | null>(configVar);
    const customer = useReactiveVar(customerVar);
    const isGuest = useReactiveVar(isGuestVar);
    const customerPassword = useReactiveVar(customerPasswordVar);
    const showJoin = config?.loyalty_config?.isLoyaltyProgramEnabled && !config?.is_portal;
    const stepsCompleted = useReactiveVar(stepsCompletedVar);
    const [isOpen, setIsOpen] = useState(false);

    if (customer || !isGuest || GetIsExplicitGuest()) return null;

    /* Only show the top divider if the payment step is active */
    const showTopDivider =
        stepsCompleted.customer && stepsCompleted.shipping && stepsCompleted.billing && stepsCompleted.embroidery;

    const minLength = config?.password_minimum_password_length || 8;
    const minCharSets = config?.password_required_character_classes_number || 3;

    const minLengthErrorMessage = t('Minimum length of this field must be equal or greater than %1 symbols. Leading and trailing spaces will be ignored.', minLength);
    const minCharSetsErrorMessage = t('Minimum of different classes of characters in password is %1. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.', minCharSets);

    const validateInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        let errorMessage = '';

        if (!value) {
            // user does not want to sign up
            removeSessionStorageData(SESSION_KEY.guestPassword);
            customerPasswordVar('');
        } else if (!isValidLength(value, minLength)) {
            errorMessage = minLengthErrorMessage;
        } else if (!validatePassword(value, minCharSets)) {
            errorMessage = minCharSetsErrorMessage;
        } else {
            setSessionStorageData(SESSION_KEY.guestPassword, value);
            customerPasswordVar(value);
        }

        setError(errorMessage);
    };

    const showHidePasswordForm = () => setIsOpen(!isOpen);

    return (
        <form
            id="password-register-form"
            className={classes({
                [styles.passwordRegisterForm]: true,
                [styles.passwordRegisterFormJoin]: showJoin,
            })}
        >
            { !showJoin && showTopDivider && <Divider /> }
            <div
                className={styles.passwordRegisterContainer}
            >
                <div
                    className={classes({
                        [fStyles.formFieldError]: error,
                        [fStyles.formField]: true,
                    })}
                >
                    <div
                        data-action="checkout-show-register-password"
                        className={styles.passwordRegisterFormHeader}
                        onClick={showHidePasswordForm}
                        onKeyDown={showHidePasswordForm}
                        role="presentation"
                    >
                        { showJoin ? (
                            <div>
                                <div className={styles.passwordRegisterHeading}>
                                    {config?.chefworks_checkout_create_account_loyalty_heading}
                                </div>
                                <div className={styles.passwordRegisterSubHeading}>
                                    {config?.chefworks_checkout_create_account_loyalty_subheading}
                                </div>
                            </div>
                        ) : (
                            <div className={styles.passwordRegisterSubHeading}>
                                {config?.chefworks_checkout_create_account_default_heading}
                            </div>
                        )}
                        <i className={`fa-solid ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'} ${styles.chevronIcon}`} />
                    </div>
                    { isOpen && (
                        <>
                            <label htmlFor="register-password" className={styles.passwordRegisterLabel}>
                                {showJoin
                                    ? config?.chefworks_checkout_create_account_loyalty_label
                                    : config?.chefworks_checkout_create_account_default_label}
                            </label>
                            <div className={styles.passwordInputContainer}>
                                <input
                                    placeholder="password"
                                    type="password"
                                    name="register-password"
                                    id="register-password"
                                    onChange={validateInput}
                                    defaultValue={customerPassword}
                                />
                                <i className={`fa-solid fa-lock ${styles.padlockIcon}`} />
                            </div>
                            {error && (<span className={fStyles.error}>{error}</span>)}
                        </>
                    )}
                </div>
            </div>
            { !showJoin && <Divider /> }
        </form>
    );
};

import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { IAddress } from './address-form';
import styles from './style.css';

interface IAddressSummaryProps {
    address: IAddress;
    title: string;
    addressIsSetVar: any;
    format?: string;
    canEdit?: boolean;
}

export const AddressSummary: React.FC<IAddressSummaryProps> = ({
    address,
    title,
    addressIsSetVar,
    format,
    canEdit = true,
}) => {
    const t = usePhraseTranslater();
    const changeAddress = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        addressIsSetVar(false);
    };
    const buttonLabel = format === 'shipping' ? t('Change Shipping Address') : t('Edit');
    return (
        <div className={format === 'shipping' ? classes(styles.addressSummary, styles.actionVert) : styles.addressSummary}>
            <div className={styles.content}>
                <div className={fStyles.hiddenField}>{title}</div>
                <div className={styles.title}>{address.company}</div>
                <div>{`${address.firstname} ${address.lastname}`}</div>
                <div>{address.street}</div>
                <div>{`${address.city} ${address.region} ${address.postcode}`}</div>
                <div>{address.countryCode}</div>
            </div>
            {canEdit && (
                <div className={styles.action}>
                    <button
                        type="submit"
                        onClick={changeAddress}
                        className={fStyles.buttonLink}
                    >
                        {buttonLabel}
                    </button>
                </div>
            )}
        </div>
    );
};

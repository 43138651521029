import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import optionImgStyles from 'ui/styles/flags.css';

interface IOptions {
    name: string;
    value: string | null;
    selected?: boolean;
}

interface IAddressFormFieldSelectProps {
    label: string;
    name: string;
    className?: string;
    options: IOptions[];
    onChange?: any;
    selectedValue?: string;
    error: string | undefined;
    disabled?: boolean;
}

export const AddressFormFieldSelect: React.FC<IAddressFormFieldSelectProps> = ({
    label, // TODO: I18N: for the 10k'th time.. I18N must take place on verbatim text! (see `t(label)` below)
    name,
    className = '',
    options,
    onChange,
    selectedValue,
    error,
    disabled,
}) => {
    const t = usePhraseTranslater();
    const hasErrors = error ?? '';
    const [optionImage, setOptionImage] = useState(selectedValue ?? '');
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setOptionImage(String(event.target.value));
        onChange(event);
    };
    const optionImg = name === 'countryCode';

    return (
        <div
            className={classes({
                [className]: className,
                [fStyles.formFieldError]: hasErrors,
                [fStyles.formField]: true,
            })}
        >
            <label htmlFor={name}>{t(label)}</label>
            <select
                id={name}
                name={name}
                onChange={handleChange}
                value={selectedValue}
                className={classes({
                    [optionImgStyles.optionImg]: optionImg,
                    [optionImgStyles[optionImage]]: optionImage,
                })}
                disabled={disabled}
            >
                {options.map(
                    option => (
                        option.value && (
                            <option
                                key={option.value}
                                value={option.value}
                                data-title={option.name}
                            >
                                { option.name }
                            </option>
                        )
                    ),
                )}
            </select>
            {hasErrors && (<span className={fStyles.error}>{error}</span>)}
        </div>
    );
};

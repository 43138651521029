import React from 'react';
import { ShippingAddressForm } from './shipping/shipping-address-form';
import { StepHeader, IStepProps } from './step-header';

export const ShippingStep = (step: IStepProps) => {
    const {
        stepNumber,
        open,
        complete,
        disabled,
    } = step;
    return (
        <StepHeader stepNumber={stepNumber} stepName="Shipping" isComplete={complete} open={open} disabled={disabled}>
            <ShippingAddressForm />
        </StepHeader>
    );
};
ShippingStep.stepKey = 'shipping';

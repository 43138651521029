declare const window: any;
// Firefox 1.0+
// @ts-ignore
export const isFirefox = typeof InstallTrigger !== 'undefined';

// Internet Explorer 6-11
// @ts-ignore
export const isIE = /* @cc_on!@ */false || !!document.documentMode;

// Edge 20+
export const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
// @ts-ignore
export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

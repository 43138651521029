import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { IPublishEventProps, PublishEvent } from '@silkpwa/module/app-event-bus/publish-event';
import { cartVar } from './checkout-state';

export const CheckoutEvent: React.FC<IPublishEventProps> = ({
    eventName,
    eventData,
    fireCondition = !!eventData,
    fireOnce,
    debugData,
}) => {
    const cart = useReactiveVar(cartVar) || {}; // TODO provide proper typing for cart data
    return (
        <PublishEvent
            eventData={eventData}
            eventName={eventName}
            fireOnce={fireOnce}
            fireCondition={fireCondition && !!cart?.items}
            debugData={debugData}
        />
    );
};

import { gql, TypedDocumentNode } from '@apollo/client';

export const APPLY_GIFT_CARD_TO_CART: TypedDocumentNode = gql`
    mutation APPLY_GIFT_CARD_TO_CART(
        $cartId: String!
        $giftCardCode: String!
   ) {
        applyGiftCardToCart(
            input: {
                cart_id: $cartId
                gift_card_code: $giftCardCode
            }
        ) {
            cart {
                applied_gift_cards {
                    applied_balance {
                        value
                        currency
                    }
                    code
                    current_balance {
                        value
                        currency
                    }
                    expiration_date
                }
            }
        }
    }
`;

export const REMOVE_GIFT_CARD_FROM_CART: TypedDocumentNode = gql`
    mutation REMOVE_GIFT_CARD_FROM_CART(
        $cartId: String!
        $giftCardCode: String!
    ) {
        removeGiftCardFromCart(
            input: {
                cart_id: $cartId
                gift_card_code: $giftCardCode
            }
        ) {
            cart {
                applied_gift_cards {
                    code
                }
            }
        }
    }
`;

import { gql, TypedDocumentNode } from '@apollo/client';
import { CART_FIELDS } from '../fragments';

interface IAssignCustomerToGuestCartInput {
    'cart_id': string;
}

interface IAssignCustomerToGuestCartOutput {
    assignCustomerToGuestCart: {
        [key: string]: any;
    };
}

export const ASSIGN_CUSTOMER_TO_GUEST_CART: TypedDocumentNode<IAssignCustomerToGuestCartOutput, IAssignCustomerToGuestCartInput> = gql`
    ${CART_FIELDS}
    mutation ASSIGN_CUSTOMER_TO_GUEST_CART($cart_id: String!) {
        assignCustomerToGuestCart(cart_id: $cart_id) {
            ...CartFields
        }
    }
`;

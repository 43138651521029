import React from 'react';
import { ILiquidPixelImage } from './preview';
import styles from './style.css';

interface IPreviewSliderProps {
    handlePreviewSelectedAt: (index: number) => void;
    lpImages: ILiquidPixelImage[];
}

export const PreviewSlider: React.FC<IPreviewSliderProps> = ({
    handlePreviewSelectedAt,
    lpImages,
}) => {
    if (!lpImages) {
        return null;
    }
    return (
        <div className={styles.previewSlider}>
            {lpImages.map((image, index) => (
                <div
                    className={styles.previewSliderItem}
                    onClick={(e) => {
                        e.preventDefault();
                        handlePreviewSelectedAt(index);
                    }}
                    role="presentation"
                >
                    <img
                        src={image.regular}
                        className={styles.previewSliderItemImg}
                        alt={image.face}
                    />
                </div>
            ))}
        </div>
    );
};

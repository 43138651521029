import { gql, TypedDocumentNode } from '@apollo/client';

/**
 * As of 2.4.7 the placeOrder mutation will return orderV2 so this should be updated when we upgrade
 */

export const PLACE_ORDER: TypedDocumentNode = gql`
    mutation PLACE_ORDER(
        $cartId: String!
        $termsAndConditions: [TermsAndConditionsInput]
        $cardNumber: String
        $expiryMonth: String
        $expiryYear: String
        $cvv: String
        $isActivePaymentTokenEnabler: Boolean
        $purchaseOrderNumber: String
    ){
        placeOrder(
            input: {
                cart_id: $cartId,
                terms_and_conditions: $termsAndConditions,
                cc_number: $cardNumber
                cc_exp_month: $expiryMonth
                cc_exp_year: $expiryYear
                cc_cid: $cvv
                is_active_payment_token_enabler: $isActivePaymentTokenEnabler
                purchase_order_number: $purchaseOrderNumber
            }
        ) {
            order {
                order_number
            }
        }
    }
`;

import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';
import { ProductInfoLine } from './product-info-line';

interface IProductInfoImgLineProps {
    className?: string;
    showEmptyLabel?: boolean;
    label: string;
    value: string;
    images?: string[];
    imageUrl?: string;
}

/* eslint-disable react/no-danger */
export const ProductInfoImgLine: React.FC<IProductInfoImgLineProps> = ({
    className = '',
    showEmptyLabel = false,
    label,
    value,
    images,
    imageUrl = '',
}) => {
    if (!showEmptyLabel && !value) return null;
    return (
        <div className={classes(className, styles.infoImageLine)}>
            <ProductInfoLine label={label} value={value} />
            <div className={styles.infoImageWrapper}>
                {images && images.map(image => (
                    <div dangerouslySetInnerHTML={{ __html: image }} className={styles.infoImage} />
                ))}
                {imageUrl && (
                    <div className={styles.infoImage}>
                        <img style={{ maxWidth: '100px', maxHeight: '100px' }} src={imageUrl} alt="" />
                    </div>
                )}
            </div>
        </div>
    );
};

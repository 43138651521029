import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_PAYPAL_EXPRESS_TOKEN: TypedDocumentNode = gql`
    mutation GET_PAYPAL_EXPRESS_TOKEN(
        $cartId: String!
    ){
        createPaypalExpressToken(
            input: {
                cart_id: $cartId
                code: "paypal_express"
                express_button: true
                urls: {
                    return_url: "checkout"
                    cancel_url: "checkout"
                }
            }
        ) {
            token
            paypal_urls {
                start
                edit
            }
        }
    }
`;

export const SET_PAYPAL_PAYMENT_METHOD: TypedDocumentNode = gql`
    mutation SET_PAYPAL_PAYMENT_METHOD(
        $cartId: String!
        $paymentMethodCode: String!
        $payerId: String!
        $paypalToken: String!
    ){
        setPaymentMethodOnCart(input: {
            cart_id: $cartId
            payment_method: {
                code: $paymentMethodCode
                paypal_express: {
                    payer_id: $payerId
                    token: $paypalToken
                }
            }
        }) {
            cart {
                selected_payment_method {
                    code
                }
            }
        }
    }
`;

import React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
    cartSummaryItemsVar,
    configVar,
    customerVar,
} from 'ui/page/checkout-page/checkout-state';
import { getSku } from 'ui/component/checkout/util/get-from-cart-item';
import { Coupon } from './coupon';
import { GiftCard } from './gift-card';
import { MethodHeader } from '../method-header/method-header';

interface ICouponGiftCardProps {
    updateCart: () => Promise<void>;
}

export const CouponGiftCard: React.FC<ICouponGiftCardProps> = ({ updateCart }) => {
    const config = useReactiveVar(configVar);
    const customer = useReactiveVar(customerVar);
    const cartSummaryItems = useReactiveVar(cartSummaryItemsVar);
    const allowedCouponAndGiftCard = () => {
        const isPortalCustomer = config?.is_portal && customer?.portal?.code;
        const isSubscriptionQuote = () => {
            const paidTiersObj = config?.loyalty_config?.paidTierSku;
            if (!cartSummaryItems || !paidTiersObj) {
                return false;
            }
            for (let i = 0; i < cartSummaryItems.length; i += 1) {
                if ((Object.values(paidTiersObj)).indexOf(getSku(cartSummaryItems[i])) !== -1) {
                    return true;
                }
            }
            return false;
        };
        return !(isPortalCustomer || isSubscriptionQuote());
    };
    return (
        <>
            {allowedCouponAndGiftCard() && (
                <MethodHeader methodName="Coupon Code / Gift Card" open={false} identifier="coupon-code-gift-card">
                    <Coupon updateCart={updateCart} />
                    <GiftCard updateCart={updateCart} />
                </MethodHeader>
            )}
        </>
    );
};

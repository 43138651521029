import { gql, TypedDocumentNode } from '@apollo/client';

// Define the types for the mutation variables
interface ISetOrderCommentOnCartVariables {
    input: {
        'cart_id': string;
        'order_comment': string;
    };
}

// Define the types for the mutation response
interface ISetOrderCommentOnCartData {
    setOrderCommentOnCart: {
        cart: {
            id: string;
        };
    };
}

export const SET_ORDER_COMMENT_ON_CART: TypedDocumentNode<ISetOrderCommentOnCartData, ISetOrderCommentOnCartVariables> = gql`
    mutation SET_ORDER_COMMENT_ON_CART($input: SetOrderCommentOnCartInput) {
        setOrderCommentOnCart(input: $input) {
            cart {
                id
            }
        }
    }
`;

import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import {
    LiquidPixelPreview,
} from 'ui/component/embroidery-configurator/components/preview/liquid-pixel/liquid-pixel-preview';
import { PreviewNav } from './preview-nav';
import { PreviewHeader } from './preview-header';
import styles from './style.css';
import { PreviewSlider } from './preview-slider';

interface IPreviewProp {
    item: any;
    handleClose: () => void;
}

export interface ILiquidPixelImage {
    face: string;
    regular: string;
    large: string;
}

// TODO: needs to implement
export const Preview: React.FC<IPreviewProp> = ({ item, handleClose }) => {
    const uniqueLpImages: ILiquidPixelImage[] | [] = item?.liquid_pixel_images.reduce((acc, current) => {
        if (!acc.some(item => item.face === current.face)) {
            acc.push(current);
        }
        return acc;
    }, []);
    const [lpImages] = useState<ILiquidPixelImage[] | []>(uniqueLpImages);
    const [face, setFace] = useState('front');
    const [previewUrl, setPreviewUrl] = useState('');
    const [zoomPreviewUrl, setZoomPreviewUrl] = useState('');
    const getCurrentPreviewIndex = (): number => lpImages.findIndex(
        (lpImage: ILiquidPixelImage) => lpImage.face === face,
    );

    const initPreview = (previewImage?: ILiquidPixelImage) => {
        if (previewImage) {
            setFace(previewImage.face);
            setPreviewUrl(previewImage.regular);
            setZoomPreviewUrl(previewImage.large);
        }
    };

    const handleNext = () => {
        const index = getCurrentPreviewIndex();
        const nextIndex = index < lpImages.length - 1 ? index + 1 : null; // null if no next index
        if (nextIndex !== null && nextIndex >= 0) {
            initPreview(lpImages[nextIndex]);
        }
    };

    const handlePrevious = () => {
        const index = getCurrentPreviewIndex();
        const previousIndex = index > 0 ? index - 1 : null; // null if no previous index
        if (previousIndex !== null && previousIndex >= 0) {
            initPreview(lpImages[previousIndex]);
        }
    };

    const handlePreviewSelectedAt = (index: number) => {
        if (index >= 0 && index < lpImages.length) {
            initPreview(lpImages[index]);
        }
    };

    const handleSwipe = (direction: string) => {
        if (direction === 'left') {
            handleNext();
        } else if (direction === 'right') {
            handlePrevious();
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe('left'),
        onSwipedRight: () => handleSwipe('right'),
    });

    useEffect(() => {
        if (lpImages.length) {
            const previewImage: ILiquidPixelImage | undefined = lpImages.find(
                (lpImage: ILiquidPixelImage) => lpImage.face === face,
            );
            initPreview(previewImage);
        }
    }, [lpImages, face]);
    return (
        /* eslint-disable react/jsx-props-no-spreading */
        <div className={styles.previewWindow} {...handlers}>
            <PreviewHeader handleClose={handleClose} face={face} />
            <div className={styles.previewImageWrapper}>
                <LiquidPixelPreview imageLink={previewUrl} zoomImageLink={zoomPreviewUrl} />
            </div>
            <PreviewSlider handlePreviewSelectedAt={handlePreviewSelectedAt} lpImages={lpImages} />
            <PreviewNav handleNext={handleNext} handlePrevious={handlePrevious} />
        </div>
        /* eslint-enable react/jsx-props-no-spreading */
    );
};

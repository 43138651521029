import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

export const CreditCard: React.FC<{ title: string }> = ({ title }) => {
    const t = usePhraseTranslater();
    return (
        <>
            <i className="fa-regular fa-credit-card" />
            <span>{title ? t(title) : t('Credit Card')}</span>
        </>
    );
};

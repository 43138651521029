import React from 'react';
import { CreditCardForm, ICreditCardInfo } from '../credit-card-form/credit-card-form';
import { IOnVariablesChange } from '../payment-options';

export const Repay = ({ onVariablesChange }: { onVariablesChange: IOnVariablesChange }) => {
    const handleCreditCardInfoChange = (creditCardInfo: ICreditCardInfo) => {
        onVariablesChange({
            paymentMethodCode: 'repay',
            ...creditCardInfo,
        });
    };
    return (
        <>
            <CreditCardForm
                onCreditCardInfoChange={handleCreditCardInfoChange}
                formId="repay"
                formTitle="Credit Card"
            />
        </>
    );
};

import React, { useEffect, useState } from 'react';
import ReactImageMagnify from '@blacklab/react-image-magnify';
import { LoadingImage } from 'ui/component/loading-image';
import { isIE } from '@silkpwa/module/util/browser';

import styles from './style.css';

export const LiquidPixelPreview = ({ imageLink, zoomImageLink }) => {
    const [loaded, setLoaded] = useState(false);
    const [loadedZoom, setLoadedZoom] = useState(false);
    // TODO: CWM2-7570 Remove zooming feature implementation from code base, CWM2-7297 disables zoom
    const enableZoom = false;
    useEffect(() => {
        // on Checkout we want to show loading state when next/previous button is clicked
        // There is no side effect on embroidery configurator
        setLoaded(false);
    }, [imageLink]);
    return (
        <>
            {loaded ? null : <LoadingImage parentClass="liquid-pixel-preview-loading" />}
            <div className={styles.previewZoomContainer} style={loaded || loadedZoom ? {} : { visibility: 'hidden', margin: 0 }}>
                <img
                    className="liquid-pixel-preview-img"
                    style={loaded && (!enableZoom || !loadedZoom) ? {} : { display: 'none' }}
                    src={imageLink}
                    onLoad={() => setLoaded(true)}
                    alt=""
                />
                {(!isIE && enableZoom) ? (
                    <img
                        style={{ display: 'none' }}
                        src={zoomImageLink}
                        onLoad={() => setLoadedZoom(true)}
                        alt=""
                    />
                ) : ''}

                {(enableZoom && loadedZoom) ? (
                    <ReactImageMagnify
                        className={styles.previewZoom}
                        imageProps={{
                            alt: '',
                            onLoad: () => true,
                            src: imageLink,
                        }}
                        magnifiedImageProps={{
                            src: zoomImageLink,
                            width: 3000,
                            height: 3000,
                        }}
                    />
                ) : ''}
            </div>
        </>
    );
};

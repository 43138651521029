import React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
    configVar,
    isGuestVar,
    customerVar,
    purchaseOrderNumberVar,
} from 'ui/page/checkout-page/checkout-state';
import { MethodHeader } from '../../payment/methods/method-header/method-header';
import styles from './style.css';

export const PurchaseOrder: React.FC<{}> = () => {
    const config = useReactiveVar(configVar);
    const customer = useReactiveVar(customerVar);
    const isGuest = useReactiveVar(isGuestVar);
    const poNumber = useReactiveVar(purchaseOrderNumberVar);
    const showPoNumber: boolean | undefined =
        (config?.chefworks_checkout_po_number_enabled && !isGuest &&
            (config?.portal_config?.is_po_number_enabled || customer?.cw_po_number));
    return (
        showPoNumber ? (
            <MethodHeader open identifier="purchase-order">
                <div className={styles.poContent}>
                    <input
                        className={styles.po}
                        name="ponumber"
                        placeholder="PO Number"
                        onChange={(e) => {
                            purchaseOrderNumberVar(e.target.value);
                        }}
                        value={poNumber}
                    />
                </div>
            </MethodHeader>
        ) : null
    );
};

import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { cartIdVar, InitializeCheckout } from 'ui/page/checkout-page/checkout-state';
import { useMediaQuery } from 'react-responsive';
import { StandardLoadingImage } from 'ui/component/loading-image';
import { Cart } from 'ui/component/checkout/cart';
import { CheckoutSteps } from './checkout-steps';
import { LoaderContext } from './checkout-loader';
import styles from './style.css';

export const Checkout = () => {
    const cartId = useReactiveVar(cartIdVar);
    const isDesktop = useMediaQuery({ query: '(min-width: 1041px)' });
    const [cartLoading, setCartLoading] = useState(false);

    return (
        <>
            {cartId && (
                <LoaderContext.Provider value={{ cartLoading, setCartLoading }}>
                    <InitializeCheckout>
                        <div className={styles.checkoutGridLeft}>
                            <CheckoutSteps />
                        </div>
                        <div className={styles.checkoutGridRight}>
                            {isDesktop && <Cart />}
                        </div>
                    </InitializeCheckout>
                </LoaderContext.Provider>
            )}
            {!cartId && <StandardLoadingImage />}
        </>
    );
};

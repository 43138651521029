import React, { useState } from 'react';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { setSessionStorageData } from 'ui/util/session-storage';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import {
    cartIdVar,
    customerEmailVar,
    isGuestVar,
    stepsCompletedVar,
} from 'ui/page/checkout-page/checkout-state';
import { IS_EMAIL_AVAILABLE, SUBSCRIBE_EMAIL_TO_NEWSLETTER } from 'graphql/customer';
import { SET_GUEST_EMAIL_ON_CART } from 'graphql/cart/mutations';
import styles from './style.css';

export const EmailForm = () => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    // const isGuest = useReactiveVar(isGuestVar);
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [subscribe, setSubscribe] = useState(true);
    const stepsCompleted = useReactiveVar(stepsCompletedVar);
    const [subscribeUserToNewsletter] = useMutation(SUBSCRIBE_EMAIL_TO_NEWSLETTER, {
        variables: {
            email,
        },
        onError: () => {
            // prevents console log
        },
    });
    const [setGuestEmailOnCart] = useMutation(SET_GUEST_EMAIL_ON_CART);
    const [isEmailAvailable] = useLazyQuery(IS_EMAIL_AVAILABLE, {
        variables: {
            email,
        },
        onError: (error) => {
            setError(error.message);
        },
        onCompleted: async (data) => {
            let setGuestError = false;
            const isGuest = data?.isEmailAvailable?.is_email_available;
            if (isGuest && cartId && email) {
                // TODO: Move setGuestEmailOnCart to HOC to reduce code footprint
                await setGuestEmailOnCart({
                    variables: {
                        input: {
                            cart_id: cartId,
                            email,
                        },
                    },
                    onError: (error) => {
                        setError(error.message);
                        setGuestError = true;
                    },
                });
            }
            if (!setGuestError) {
                isGuestVar(isGuest);
                customerEmailVar(email);
                setSessionStorageData('customerEmail', email);
                stepsCompletedVar({ ...stepsCompleted, customer: data?.isEmailAvailable?.is_email_available });
            }
        },
    });
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await isEmailAvailable();
        if (subscribe) {
            await subscribeUserToNewsletter();
        }
    };
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };
    const subscribeUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubscribe(event.target.checked);
    };
    return (
        <>
            <form onSubmit={handleSubmit} className={styles.emailForm}>
                <div className={classes(fStyles.formField)}>
                    <label htmlFor="email">{t('Contact Info')}</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={onChange}
                        placeholder="email address*"
                        required
                    />
                    {error && (<span className={fStyles.error}>{t(error)}</span>)}
                </div>
                <div className={classes(fStyles.formField, fStyles.actionButton)}>
                    <button type="submit" className={fStyles.checkoutButton}>{t('Continue')}</button>
                </div>
                <div className={classes(fStyles.formField, fStyles.inline, fStyles.center)}>
                    <input type="checkbox" id="newsletter" name="newsletter" onChange={subscribeUser} defaultChecked />
                    <label htmlFor="newsletter" className={fStyles.normal}>{t('Subscribe to Newsletter')}</label>
                </div>
            </form>
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { ICustomerAddress } from 'ui/page/checkout-page/checkout-state';
import { Card } from 'ui/component/checkout/components/card';
import { Delete } from './delete';
import styles from './style.css';

interface IAddressBookAddressProps {
    address: ICustomerAddress;
    selectedAddress: ICustomerAddress | null;
    setSelectedAddress: (address: ICustomerAddress) => void;
    preselectAddress?: ICustomerAddress;
    canEdit: boolean;
}

export const AddressBookAddress: React.FC<IAddressBookAddressProps> = ({
    address, selectedAddress, setSelectedAddress, preselectAddress, canEdit,
}) => {
    const [showDelete, setShowDelete] = useState(false);
    useEffect(() => {
        if (preselectAddress && !selectedAddress && preselectAddress.id === address.id) {
            setSelectedAddress(address);
        }
    }, [preselectAddress, address, setSelectedAddress]);
    return (
        <Card identifier="address-book-address">
            <div className={styles.addressBookWrapper}>
                <div className={styles.action}>
                    <input
                        id={address.id}
                        type="radio"
                        name="address"
                        value={address.id}
                        onChange={() => setSelectedAddress(address)}
                        checked={!!selectedAddress && selectedAddress.id === address.id}
                    />
                </div>
                <div className={styles.address}>
                    {address.company && (
                        <div className={styles.title}>{address.company}</div>
                    )}
                    <div>{`${address.firstname} ${address.lastname}`}</div>
                    <div>{address.street}</div>
                    <div>{`${address.city} ${address.region.region} ${address.postcode}`}</div>
                    <div>{address.country_code}</div>
                </div>
                { canEdit && (
                    <div className={styles.action}>
                        <button type="button" className={styles.deleteButton} onClick={() => setShowDelete(true)}>
                            <i className="fa-solid fa-xmark" />
                        </button>
                    </div>
                )}
            </div>
            { showDelete && (
                <div className={styles.delete}>
                    <Delete address={address} handleClose={() => setShowDelete(false)} />
                </div>
            )}
        </Card>
    );
};

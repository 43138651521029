import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { configVar } from '../../../../../page/checkout-page/checkout-state';
import { Modal } from '../../../components/modal/modal';
import { IStoreConfig, IAgreement } from '../../../../../../graphql/config/config';
import styles from './style.css';

export const TermsLinks = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const t = usePhraseTranslater();
    const handleButtonClick = (content: string) => {
        setModalContent(content);
        setIsModalOpen(!isModalOpen);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const config = useReactiveVar<IStoreConfig|null>(configVar);
    const termsLinks = config
        ? config.terms_and_conditions_agreements.map(
            (agreement: IAgreement, index: number, array: IAgreement[]) => (
                <React.Fragment key={agreement.id}>
                    {index === array.length - 1 && ' and '}
                    <button
                        className={styles.termsLink}
                        type="button"
                        onClick={() => handleButtonClick(agreement.content)}
                    >
                        {agreement.checkbox_text}
                    </button>
                    {index < array.length - 2 && ', '}
                </React.Fragment>
            ),
        ) : null;
    return (
        <>
            { termsLinks && (
                <div className={styles.termsLinksWrapper}>
                    <span>
                        {t('By placing your order you agree to our')}
                    </span>
                    <span>
                        {termsLinks}
                    </span>
                    <Modal isOpen={isModalOpen} content={modalContent} onClose={handleCloseModal} />
                </div>
            )}
        </>
    );
};

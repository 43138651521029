import React, { useEffect } from 'react';
import { useReactiveVar, useMutation } from '@apollo/client';
import {
    SET_PAYMENT_METHOD,
    SET_PAYMENT_METHOD_BRAINTREE_VAULT,
} from 'graphql/cart/payment-method';
import { cartIdVar } from 'ui/page/checkout-page/checkout-state';
import { PaymentInfo } from '../payment-method/payment-info';
import { IPaymentMethodParams } from '../payment-options';
import { CreditCardVault } from '../payment-options/buttons-content/credit-card-vault';
import optionsStyle from '../payment-options/style.css';

export const CCVault = (
    {
        onVariablesChange,
        selectedMethod,
        setBeforePlaceOrder,
    }: IPaymentMethodParams,
) => {
    const cartId = useReactiveVar(cartIdVar);
    const getFormattedMethodCode = (methodCode: string | undefined, hash: string | undefined): string | undefined => {
        switch (methodCode) {
            case 'repay':
                return `repay_cc_vault_${hash}`;
            case 'braintree':
                return 'braintree_cc_vault';
            default:
                return methodCode;
        }
    };
    const paymentMethodMutation = selectedMethod?.payment_method_code === 'braintree'
        ? SET_PAYMENT_METHOD_BRAINTREE_VAULT
        : SET_PAYMENT_METHOD;
    const [savePaymentMethod] = useMutation(paymentMethodMutation);
    useEffect(() => {
        const formattedMethodCode =
            getFormattedMethodCode(selectedMethod?.payment_method_code, selectedMethod?.public_hash);
        onVariablesChange({
            paymentMethodCode: formattedMethodCode,
            publicHash: selectedMethod?.public_hash,
        });
        setBeforePlaceOrder(() => async () => {
            try {
                await savePaymentMethod({
                    variables: {
                        cartId,
                        paymentMethodCode: formattedMethodCode,
                        publicHash: selectedMethod?.public_hash,
                    },
                });
            } catch (error) {
                throw new Error(error);
            }
        });
    }, [selectedMethod]);

    if (!selectedMethod || !selectedMethod.code) {
        return (<PaymentInfo />);
    }

    const {
        title,
        code,
        details,
        // eslint-disable-next-line camelcase
        payment_method_code,
        // eslint-disable-next-line camelcase
        public_hash,
    } = selectedMethod;
    const selectedMethodInfo = title || code;

    return (
        <div className={optionsStyle.methodInfo}>
            <CreditCardVault
                title={selectedMethodInfo}
                code={code}
                details={details}
                /* eslint-disable-next-line camelcase */
                payment_method_code={payment_method_code}
                /* eslint-disable-next-line camelcase */
                public_hash={public_hash}
            />
        </div>
    );
};

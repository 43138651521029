import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import fStyles from '../styles/form-style.css';

// TODO: we might just need to auto redirect to home page in this case
const EmptyStep = () => {
    const t = usePhraseTranslater();
    return (
        <div style={{ padding: '2rem' }}>
            <div style={{ textAlign: 'center', margin: '2rem' }}>{t('Your cart is empty')}</div>
            <button type="submit" className={fStyles.checkoutButton} onClick={() => window.location.replace('/')}>
                {t('CONTINUE SHOPPING')}
            </button>
        </div>
    );
};
EmptyStep.stepKey = 'empty';

export { EmptyStep };

import React from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { isArray } from 'lodash';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import { SET_SHIPPING_ADDRESS } from 'graphql/cart/shipping-address';
import {
    cartIdVar,
    cartShippingAddressVar,
    cartVar,
    stepsCompletedVar,
    showVerifyAddressVar,
    validatedAddressVar,
    originalAddressVar,
    shouldValidateAddressVar,
    isShippingAddressSetVar,
} from 'ui/page/checkout-page/checkout-state';
import styles from './style.css';

const getHighlightClass = (originalValue: string, suggestedValue: string) => (originalValue !== suggestedValue ? styles.highlight : '');

export const VerifyAddress = () => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    const stepsCompleted = useReactiveVar(stepsCompletedVar);
    const validatedAddress = useReactiveVar(validatedAddressVar);
    const originalAddress = useReactiveVar(originalAddressVar);
    const [chosenAddress, setChosenAddress] = React.useState(validatedAddress);
    const originalStreet = isArray(originalAddress.street) ? originalAddress.street[0] : originalAddress.street;
    const [saveShippingAddress, { loading }] = useMutation(SET_SHIPPING_ADDRESS, {
        onCompleted: (data) => {
            stepsCompletedVar({ ...stepsCompleted, shipping: false });
            cartVar(data.setShippingAddressesOnCart.cart);
            cartShippingAddressVar({ // TODO This is a bit clunky and should be refactored
                ...chosenAddress,
                id: '',
                country: { code: chosenAddress.countryCode },
                country_code: chosenAddress.countryCode,
                region:
                    {
                        code: chosenAddress.region,
                        region_code: chosenAddress.region,
                        region: chosenAddress.region,
                    },
            });
            isShippingAddressSetVar(true);
            shouldValidateAddressVar(false);
            showVerifyAddressVar(false);
        },
    });
    const handleSave = () => {
        saveShippingAddress({
            variables: {
                cartId,
                ...chosenAddress,
                region: chosenAddress.region,
                countryCode: chosenAddress.countryCode,
            },
        });
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'originalAddress') {
            setChosenAddress(originalAddress);
        } else {
            setChosenAddress(validatedAddress);
        }
    };
    return (
        <form className={styles.verifyAddress}>
            <div className={styles.verifyHeader}>
                {t('Verify Address')}
                <button type="button" className={styles.closeButton} onClick={() => showVerifyAddressVar(false)}>
                    <i className="fa-solid fa-xmark" />
                </button>
            </div>
            <div className={styles.verifyWrapper}>
                <div className={styles.verifyAddress}>
                    <div className={styles.action}>
                        <input
                            id="originalAddress"
                            type="radio"
                            name="address"
                            value="originalAddress"
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.address}>
                        <span className={styles.bold}>{t('You Entered')}</span>
                        <div>{originalStreet}</div>
                        <div>{`${originalAddress.city} ${originalAddress.region} ${originalAddress.postcode}`}</div>
                        <div>{originalAddress.countryCode}</div>
                    </div>
                </div>
                <div className={styles.verifyAddress}>
                    <div className={styles.action}>
                        <input
                            id="suggestedAddress"
                            type="radio"
                            name="address"
                            value="suggestedAddress"
                            defaultChecked
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.address}>
                        <span className={styles.bold}>{t('We Suggest')}</span>
                        <div className={getHighlightClass(originalStreet, validatedAddress.street[0])}>
                            {validatedAddress.street[0]}
                        </div>
                        <div>
                            <span className={getHighlightClass(originalAddress.city, validatedAddress.city)}>
                                {validatedAddress.city}
                            </span>
                            &nbsp;
                            <span className={getHighlightClass(originalAddress.region, validatedAddress.region)}>
                                {validatedAddress.region}
                            </span>
                            &nbsp;
                            <span className={getHighlightClass(originalAddress.postcode, validatedAddress.postcode)}>
                                {validatedAddress.postcode}
                            </span>
                        </div>
                        <div className={getHighlightClass(originalAddress.countryCode, validatedAddress.countryCode)}>
                            {validatedAddress.countryCode}
                        </div>
                    </div>
                </div>
            </div>
            <button type="submit" className={fStyles.checkoutButton} onClick={handleSave} disabled={loading}>
                {t('Use Selected Address')}
            </button>
        </form>
    );
};


export const getStyles = (): string => `.creditkey {
  all: initial;
}
.creditkey * {
  all: unset;
}
.creditkey {
  z-index: 50000;
  text-decoration: none !important;
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* Bulma Utilities */
}
.creditkey .modal-close {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: rgba(10, 10, 10, 0.2);
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px;
}
.creditkey .modal-close::before, .creditkey .modal-close::after {
  background-color: hsl(0, 0%, 100%);
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}
.creditkey .modal-close::before {
  height: 2px;
  width: 50%;
}
.creditkey .modal-close::after {
  height: 50%;
  width: 2px;
}
.creditkey .modal-close:hover, .creditkey .modal-close:focus {
  background-color: rgba(10, 10, 10, 0.3);
}
.creditkey .modal-close:active {
  background-color: rgba(10, 10, 10, 0.4);
}
.creditkey .is-small.modal-close {
  height: 16px;
  max-height: 16px;
  max-width: 16px;
  min-height: 16px;
  min-width: 16px;
  width: 16px;
}
.creditkey .is-medium.modal-close {
  height: 24px;
  max-height: 24px;
  max-width: 24px;
  min-height: 24px;
  min-width: 24px;
  width: 24px;
}
.creditkey .is-large.modal-close {
  height: 32px;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  width: 32px;
}
.creditkey .modal-background, .creditkey .ck-modal-background, .creditkey .modal, .creditkey .ck-modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.creditkey .modal, .creditkey .ck-modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 100001;
}
.creditkey .modal.is-active, .creditkey .is-active.ck-modal {
  display: flex;
}
.creditkey .modal-background, .creditkey .ck-modal-background {
  background-color: rgba(10, 10, 10, 0.86);
}
.creditkey .modal-content, .creditkey .ck-modal-content,
.creditkey .ck-modal-card,
.creditkey .modal-card {
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .creditkey .modal-content, .creditkey .ck-modal-content,
  .creditkey .ck-modal-card,
  .creditkey .modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 650px;
  }
}
.creditkey .modal-close {
  background: none;
  height: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
}
.creditkey .modal-card, .creditkey .ck-modal-content,
.creditkey .ck-modal-card {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  -ms-overflow-y: visible;
}
.creditkey .modal-card-head,
.creditkey .modal-card-foot {
  align-items: center;
  background-color: hsl(0, 0%, 96%);
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
}
.creditkey .modal-card-head {
  border-bottom: 1px solid hsl(0, 0%, 86%);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.creditkey .modal-card-title {
  color: hsl(0, 0%, 21%);
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}
.creditkey .modal-card-foot {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid hsl(0, 0%, 86%);
}
.creditkey .modal-card-foot .button:not(:last-child) {
  margin-right: 0.5em;
}
.creditkey .modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: hsl(0, 0%, 100%);
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 20px;
}
.creditkeya {
  text-decoration: none !important;
}
.creditkey .button {
  background-color: #3D9CE5 !important;
  min-height: 40px !important;
  border-width: 0 !important;
  vertical-align: middle !important;
  text-decoration: none !important;
}
.creditkey .ck-modal {
  margin: 0 !important;
  padding-top: 50px;
  max-width: 100% !important;
  width: 100% !important;
  visibility: visible !important;
  background: transparent !important;
  position: absolute;
  justify-content: normal;
}
@media screen and (max-device-width: 480px) {
  .creditkey .ck-modal {
    padding-top: 0px !important;
  }
}
.creditkey .ck-modal-background {
  position: fixed;
}
.creditkey .ck-modal-content,
.creditkey .ck-modal-card {
  min-height: min-content !important;
  max-height: none;
  height: auto !important;
}
@media screen and (min-width: 769px), print {
  .creditkey .ck-modal-content,
  .creditkey .ck-modal-card {
    min-height: min-content !important;
    max-height: none;
    height: auto !important;
  }
}
.creditkey .ck-modal-card {
  min-height: min-content !important;
  max-height: none !important;
}
.creditkey .ck-modal-content {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  border-radius: 5px;
  background-color: #fff;
  background-image: url("https://www.creditkey.com/app/assets/header/ck-nav-logo-d79f74bc03213d02a5ab4cd1c484cfcfb533c2abf5f05ee35cd67c5239693a28.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  min-height: min-content;
  max-height: none;
}
@media screen and (max-width: 1023px) {
  .creditkey .ck-modal-content .ck-modal-content {
    height: 100%;
    border-radius: 0 !important;
  }
}
.creditkey #creditkey-iframe {
  margin: auto;
  width: 100%;
  border: none;
  height: inherit;
}
.creditkey .payment-icon {
  display: inline-block !important;
  margin-right: 5px !important;
  vertical-align: middle !important;
}
.creditkey .terms {
  text-decoration: underline;
  color: #3D9CE5;
  cursor: pointer;
}
.creditkey .terms:hover {
  text-decoration: none;
}
.creditkey .pdp {
  padding: 0 5px 0 0;
  font-size: 16px !important;
  font-weight: bold;
}
.creditkey .pdp-text {
  font-size: 16px !important;
  font-weight: 400;
}
.creditkey .ck-offer {
  float: right;
  text-align: left;
}
.creditkey .ck-logo-small {
  height: 22px !important;
}
.creditkey .ck-logo-medium {
  height: 22px !important;
}
.creditkey .ck-logo-large {
  height: 22px !important;
}

#creditkey-pdp-iframe {
  width: 100% !important;
  max-height: 70px !important;
}
`;

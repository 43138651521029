import React from 'react';
import { PaymentForm } from './payment-form';
import { IPaymentOptionsProps } from './methods/payment-options';

export const Payment =
    ({ setBeforePlaceOrder, setAfterPlaceOrder }: IPaymentOptionsProps) => (
        <PaymentForm
            setBeforePlaceOrder={setBeforePlaceOrder}
            setAfterPlaceOrder={setAfterPlaceOrder}
        />
    );

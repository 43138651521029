import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IProductInfoLineProps {
    className?: string;
    showEmptyLabel?: boolean;
    label: string;
    value: string;
}

export const ProductInfoLine: React.FC<IProductInfoLineProps> = ({
    className = '',
    showEmptyLabel = false,
    label,
    value,
}) => {
    if (!showEmptyLabel && !value) return null;
    return (
        <div className={classes(className, styles.infoLine)}>
            {label && (<span className={styles.label}>{label}</span>)}
            <span className={styles.value}>{value}</span>
        </div>
    );
};

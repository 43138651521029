import React from 'react';
import { ProductInfo } from './product-info';
import { ProductEmbInfo } from './product-emb-info';
import styles from './style.css';

export const Details = ({ item, handleClose }) => (
    <div className={styles.detailWindow}>
        <button type="button" className={styles.closeButton} onClick={handleClose}>
            <i className="fa-solid fa-xmark" />
        </button>
        <ProductInfo item={item} />
        <ProductEmbInfo item={item} />
    </div>
);

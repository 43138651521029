export const CREDIT_CARD_ERRORS = {
    HOLDER_NAME: 'cardHolderNameError',
    NUMBER: 'cardNumberError',
    EXPIRY_DATE_WRONG_FORMAT: 'cardExpiryDateWrongFormatError',
    EXPIRY_DATE_EXPIRED: 'cardExpiryDateExpiredError',
    CVV: 'cardCvvError',
} as const;

export const validateCardHolderName = (value: string): string => {
    if (value) {
        const regex = /^[a-zA-Z\s]+$/;
        if (!regex.test(value) || value.trim() === '') {
            return CREDIT_CARD_ERRORS.HOLDER_NAME;
        }
    }
    return '';
};

export const validateCardNumber = (value: string): string => {
    const digits = value.replace(/\D/g, '');
    if (digits.length < 13 || digits.length > 19) {
        return CREDIT_CARD_ERRORS.NUMBER;
    }

    let sum = 0;
    for (let i = 0; i < digits.length; i += 1) {
        let digit = parseInt(digits[i], 10);
        if ((digits.length - i) % 2 === 0) {
            digit *= 2;
            if (digit > 9) {
                digit -= 9;
            }
        }

        sum += digit;
    }

    if (sum % 10 !== 0) {
        return CREDIT_CARD_ERRORS.NUMBER;
    }

    return '';
};

export const validateExpiryDate = (value: string): string => {
    const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
    if (!regex.test(value)) {
        return CREDIT_CARD_ERRORS.EXPIRY_DATE_WRONG_FORMAT;
    }

    const [month, year] = value.split('/').map(Number);
    const expiryDate = new Date(year + 2000, month, 0);
    if (expiryDate < new Date()) {
        return CREDIT_CARD_ERRORS.EXPIRY_DATE_EXPIRED;
    }

    return '';
};

export const validateCvv = (value: string): string => {
    const regex = /^\d{3,4}$/;
    if (!regex.test(value)) {
        return CREDIT_CARD_ERRORS.CVV;
    }

    return '';
};

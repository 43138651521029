import { gql, TypedDocumentNode } from '@apollo/client';

interface IRevokeCustomerTokenOutput {
    revokeCustomerToken: {
        result: boolean;
    };
}

export const REVOKE_CUSTOMER_TOKEN: TypedDocumentNode<{}, IRevokeCustomerTokenOutput> = gql`
    mutation REVOKE_CUSTOMER_TOKEN {
        revokeCustomerToken {
            result
        }
    }
`;

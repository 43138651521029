import { gql, TypedDocumentNode } from '@apollo/client';

interface ICreateCustomerVariable {
    input: {
        email: string;
        firstname: string;
        lastname: string;
        password: string;
        'is_subscribed': boolean;
    };
}

interface ICreateCustomerData {
    createCustomer: {
        email: string;
        firstname: string;
        lastname: string;
        'is_subscribed': boolean;
    };
}

export const CREATE_CUSTOMER: TypedDocumentNode<ICreateCustomerData, ICreateCustomerVariable> = gql`
    mutation CREATE_CUSTOMER($input: CustomerInput!) {
        createCustomer(input: $input) {
            customer {
                email
                firstname
                lastname
                is_subscribed
            }
        }
    }
`;

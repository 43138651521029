import React, { useEffect } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
    GET_PAYPAL_EXPRESS_TOKEN,
    SET_PAYPAL_PAYMENT_METHOD,
} from 'graphql/cart/payment-method/paypal';
import {
    cartIdVar,
    IAvailablePaymentMethod,
    shouldTryPlaceOrderVar,
    PAYPAL_EXPRESS,
} from 'ui/page/checkout-page/checkout-state';
import { IPaymentMethodParams } from '../payment-options';
import { PaymentInfo } from '../payment-method/payment-info';
import { PaypalExpress as PaypalExpressButton } from '../payment-options/buttons-content/paypal-express';
import optionsStyle from '../payment-options/style.css';

interface IObtainedCredentials {
    payerId: string;
    paypalToken: string;
}

interface IPayPalTokenResponse {
    data: {
        createPaypalExpressToken: {
            // eslint-disable-next-line camelcase
            paypal_urls: {
                edit: string;
                start: string;
            };
            token: string;
        };
    };
}

export const PaypalExpress = (
    {
        onVariablesChange,
        setBeforePlaceOrder,
        selectedMethod,
    }: IPaymentMethodParams,
) => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    const [setPaymentMethod] = useMutation(SET_PAYPAL_PAYMENT_METHOD);
    const [getPaypalToken] = useMutation(GET_PAYPAL_EXPRESS_TOKEN, {
        variables: {
            cartId,
        },
    });

    const getObtainedCredentials = (): IObtainedCredentials => {
        const params = new URLSearchParams(window.location.search);
        const paypalToken = params.get('token') || '';
        const payerId = params.get('PayerID') || '';
        return {
            payerId,
            paypalToken,
        };
    };

    const setPayPalExpressOnCart = (payerId: string, paypalToken: string) => setPaymentMethod({
        variables: {
            cartId,
            paymentMethodCode: 'paypal_express',
            payerId,
            paypalToken,
        },
    }).then(() => {
        // success!
    }).catch(() => {
        throw new Error(t('Could not set PayPal Express payment method.'));
    });

    const obtainPayPalToken = () => getPaypalToken().then((paypalResponse) => {
        const response = paypalResponse as IPayPalTokenResponse;
        if (response?.data?.createPaypalExpressToken?.paypal_urls?.start) {
            window.open(
                response.data.createPaypalExpressToken.paypal_urls.start,
                '_parent',
            );
            throw new Error(t('Info:Start PayPal Express processing...'));
        } else {
            throw new Error(t('Could not start PayPal Express processing.'));
        }
    });

    const processPayPal = () => async () => {
        const { payerId, paypalToken } = getObtainedCredentials();
        if (paypalToken && payerId) {
            await setPayPalExpressOnCart(payerId, paypalToken);
            return;
        }

        await obtainPayPalToken();
    };

    useEffect(() => {
        onVariablesChange({
            paymentMethodCode: selectedMethod?.code ?? '',
        });
    }, [selectedMethod]);

    useEffect(() => {
        const { payerId, paypalToken } = getObtainedCredentials();
        const shouldTryPlaceOrder = Boolean(paypalToken && payerId);
        /**
         * Set `shouldTryPlaceOrder` for start placing an order as PayPal has obtained credentials, so that a
         * customer/guest would not need to click `Pay` button again.
         */
        shouldTryPlaceOrderVar(shouldTryPlaceOrder);
        /**
         * Override `setBeforePlaceOrder` in order to process PayPal Express Payment method.
         */
        setBeforePlaceOrder(processPayPal);
    }, []);

    const isPayPalSet = () => Boolean(selectedMethod && selectedMethod.code === PAYPAL_EXPRESS);
    if (!isPayPalSet()) {
        return (<PaymentInfo />);
    }

    const {
        title,
        code,
    } = selectedMethod as IAvailablePaymentMethod;
    const selectedMethodInfo = title || code;

    return (
        <>
            {selectedMethod?.code && (
                <div className={optionsStyle.methodInfo}>
                    <PaypalExpressButton title={selectedMethodInfo} />
                </div>
            )}
        </>
    );
};

import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { CustomerStep } from 'ui/component/checkout/steps/customer';
import { ShippingStep } from 'ui/component/checkout/steps/shipping';
import { BillingStep } from 'ui/component/checkout/steps/billing';
import { EmbroideryStep } from 'ui/component/checkout/steps/embroidery';
import { PaymentStep } from 'ui/component/checkout/steps/payment';
import { EmptyStep } from 'ui/component/checkout/steps/empty';
import { Loading } from 'ui/component/checkout/steps/loading';
import { IStepProps } from 'ui/component/checkout/steps/step-header';
import {
    cartEmbroideryVar,
    cartSelectedShippingMethodVar,
    cartVar, customerEmailVar, isBillingAddressSetVar,
    isLoggedInVar,
    isShippingAddressSetVar,
    stepsCompletedVar,
} from './checkout-state';
import {
    CHECKOUT_BILLING_ADDRESS,
    CHECKOUT_CART,
    CHECKOUT_EMAIL,
    CHECKOUT_LOGIN,
    CHECKOUT_SHIPPING,
    CHECKOUT_SHIPPING_ADDRESS,
    CHECKOUT_STEP,
    CHECKOUT_STEP_START,
} from './events';
import { CheckoutEvent } from './checkout-event';


type StepComponentType = React.ComponentType<IStepProps> & { stepKey: string };

export const CheckoutSteps = () => {
    const [checkoutSteps, setCheckoutSteps] =
        useState<StepComponentType[]>([Loading]);
    const stepsCompleted = useReactiveVar(stepsCompletedVar);
    const pathParts = window.location.pathname.split('/');
    const cart = useReactiveVar(cartVar);
    const customerEmail = useReactiveVar(customerEmailVar);
    const isLoggedIn = useReactiveVar(isLoggedInVar);
    const isShippingAddressSet = useReactiveVar(isShippingAddressSetVar);
    const isBillingAddressSet = useReactiveVar(isBillingAddressSetVar);
    const shippingMethod = useReactiveVar(cartSelectedShippingMethodVar);
    const embroideryItems = useReactiveVar(cartEmbroideryVar);

    let activeStep = pathParts.includes('checkout') ? pathParts[pathParts.indexOf('checkout') + 1] : 'loading';
    const firstIncompleteStep =
        Object.keys(stepsCompleted).find(key => !stepsCompleted[key]) ?? 'customer';
    if (
        Object.keys(stepsCompleted).indexOf(activeStep) > Object.keys(stepsCompleted).indexOf(firstIncompleteStep) ||
        Object.keys(stepsCompleted).indexOf(activeStep) === -1
    ) {
        activeStep = firstIncompleteStep; // Don't allow user to jump ahead of completed steps and ignore invalid steps
    }
    const openStep = activeStep ?? firstIncompleteStep;
    useEffect(() => {
        if (!cart) {
            setCheckoutSteps([Loading]);
        } else if (cart?.items.length === 0) {
            setCheckoutSteps([EmptyStep]);
        } else {
            let steps: StepComponentType[] = [CustomerStep];
            if (!cart?.is_virtual) {
                steps = [...steps, ShippingStep];
            }
            steps = [...steps, BillingStep];
            if (embroideryItems !== undefined && embroideryItems.length > 0) {
                steps = [...steps, EmbroideryStep];
            }
            steps = [...steps, PaymentStep];
            setCheckoutSteps(steps);
        }
    }, [cart, embroideryItems, openStep]);

    return (
        <>
            {Object.keys(stepsCompleted).map(step => (
                <CheckoutEvent key={`event-${step}`} eventName={CHECKOUT_STEP} eventData={step} fireCondition={stepsCompleted[step as keyof typeof stepsCompleted]} />
            ))}
            <CheckoutEvent eventName={CHECKOUT_CART} eventData={cart} />
            <CheckoutEvent eventName={CHECKOUT_STEP_START} eventData={cart} fireOnce />
            <CheckoutEvent eventName={CHECKOUT_EMAIL} eventData={customerEmail} />
            <CheckoutEvent eventName={CHECKOUT_LOGIN} eventData={isLoggedIn} />
            <CheckoutEvent eventName={CHECKOUT_SHIPPING_ADDRESS} eventData={isShippingAddressSet} />
            <CheckoutEvent eventName={CHECKOUT_SHIPPING} eventData={shippingMethod} />
            <CheckoutEvent eventName={CHECKOUT_BILLING_ADDRESS} eventData={isBillingAddressSet} />
            {checkoutSteps.map((Step: StepComponentType, index) => {
                const { stepKey } = Step;
                if (stepKey) {
                    const disabled =
                        Object.keys(stepsCompleted).indexOf(stepKey) >
                        Object.keys(stepsCompleted).indexOf(firstIncompleteStep);
                    return (
                        <Step
                            key={Step.name}
                            stepNumber={index + 1}
                            complete={stepsCompleted[stepKey as keyof typeof stepsCompleted]}
                            open={openStep === stepKey}
                            disabled={disabled}
                        />
                    );
                }
                return null;
            })}
        </>
    );
};

import { gql, TypedDocumentNode } from '@apollo/client';

interface ICustomerToken {
    token: string;
}

interface IGenerateCustomerToken {
    generateCustomerToken: ICustomerToken;
}

export const GENERATE_CUSTOMER_TOKEN: TypedDocumentNode<IGenerateCustomerToken> = gql`
    mutation GENERATE_CUSTOMER_TOKEN(
        $email: String!,
        $password: String!
    ) {
        generateCustomerToken(
            email: $email,
            password: $password
        ) {
            token
        }
    }
`;

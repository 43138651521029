import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import optionsStyle from '../payment-options/style.css';

export const PaymentInfo = ({ infoText }: { infoText?: string }) => {
    const t = usePhraseTranslater();
    return (
        <div
            className={classes({
                [optionsStyle.methodInfo]: true,
                [optionsStyle.notSelected]: true,
            })}
        >
            {infoText && (
                <h3>{infoText}</h3>
            )}
            {!infoText && (
                <h3>{t('Payment Method has not been selected or not available.')}</h3>
            )}
        </div>
    );
};

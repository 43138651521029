import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IButtonProps {
    loading?: boolean;
}

// TODO: need to make this a processing button with event handling. which can utilize/replace existing buttons
export const Process: React.FC<IButtonProps> = ({ loading = false }) => {
    if (!loading) {
        return null;
    }
    return (
        <i className={classes('fas fa-spinner', styles.spinner)} />
    );
};

import React, { useEffect, useState } from 'react';
import { useContainer } from '@silkpwa/redux';
import { AppEventBus } from '@silkpwa/module/app-event-bus/app-event-bus';

export interface IPublishEventProps {
    eventName: string;
    eventData: any /* any OK */;
    fireCondition?: boolean;
    fireOnce?: boolean;
    debugData?: any; /* any OK */
}

export const PublishEvent: React.FC<IPublishEventProps> = ({
    eventName,
    eventData,
    fireCondition = !!eventData,
    fireOnce = false,
}) => {
    const [fired, setFired] = useState(false);
    const appEventBus = useContainer<AppEventBus>('appEventBus');
    const fireEvent = fireCondition && (!fired || !fireOnce);

    useEffect(() => {
        if (fireEvent) {
            setFired(true);
            appEventBus.publish(eventName, eventData);
        }
    }, [eventData, eventName, fireCondition]);

    const payload = typeof eventData === 'string' ? eventData : JSON.stringify(eventData);
    return (
        <div
            data-published={fired ? 'true' : 'false'}
            data-published-now={fireEvent ? 'true' : 'false'}
            data-name={eventName}
            data-fire-condition={fireCondition ? 'true' : 'false'}
            data-fire-once={fireOnce ? 'true' : 'false'}
            data-value={payload}
        />
    );
};
